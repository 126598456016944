import React, { FC, UIEvent, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { groupBy, mapValues } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { SHARE_EXCHANGE_MULTIPLE_SNIPPET_TITLE, SHARE_EXCHANGE_SNIPPET_TITLE } from 'constants/dashboard';
import { DEALS_TABS_IDS } from 'constants/deals';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { getDashboardDeals, getDealStages } from 'modules/deals/action';
import {
  selectDashboardDeals,
  selectIsDealStagesExists,
  selectIsLoadingDeals,
  selectTotalDeals,
} from 'modules/deals/selectors';
import { selectIsLoading as selectIsLoadingCompaniesWithInvestments } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, Checkbox, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';
import { checkIsInvestor } from 'utils';

import ShareExchangeCompanyGroup from './ShareExchangeCompanyGroup';
import ShareExchangeInvestorSnippetItem from './ShareExchangeInvestorSnippetItem';
import ShareExchangeSkeleton from './ShareExchangeSkeleton';
import ShareExchangeSnippetItem from './ShareExchangeSnippetItem';
import ShareExchangeSnippetNoInfo from './ShareExchangeSnippetNoInfo';

const DEALS_PER_PAGE = 4;

const FILTER_BY_IN_PROGRESS_STATUS = 'filter[status][]=inProgress';
const FILTER_BY_DONE_STATUS = `filter[status][]=done&${FILTER_BY_IN_PROGRESS_STATUS}`;

interface Props {
  className?: string;
}

const ShareExchangeSnippet: FC<Props> = ({ className }) => {
  const itemsWrapperRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deals = useAppSelector(selectDashboardDeals);
  const isLoadingDeals = useAppSelector(selectIsLoadingDeals);
  const isLoadingCompaniesWithInvestments = useAppSelector(selectIsLoadingCompaniesWithInvestments);
  const totalDeals = useAppSelector(selectTotalDeals);
  const isDealStagesExists = useAppSelector(selectIsDealStagesExists);

  const [showCompletedShareExchanges, setShowCompletedShareExchanges] = useState(false);

  const skeletonItems = deals?.length || DEALS_PER_PAGE;
  const isMultipleDeals = deals?.length && deals.length >= 2;

  const isInvestor = checkIsInvestor();

  const groupedFounderDeals = useMemo(() => {
    return mapValues(groupBy(deals, 'companyName'));
  }, [deals]);

  const isLoading = useMemo(
    () => isLoadingDeals || isLoadingCompaniesWithInvestments,
    [isLoadingDeals, isLoadingCompaniesWithInvestments],
  );

  const onChangeShowCompletedShareExchanges = () => {
    setShowCompletedShareExchanges((prevState) => !prevState);
  };

  const onClickViewAll = () => {
    navigate({ pathname: ROUTES.deals, search: `?tab=${DEALS_TABS_IDS.SHARE_EXCHANGES}` });
  };

  const fetchMoreItems = () => {
    const loadedDeals = deals?.length || 0;

    if (isLoadingDeals || totalDeals === loadedDeals) {
      return;
    }

    const currentPage = deals ? deals.length / DEALS_PER_PAGE : 1;

    dispatch(
      getDashboardDeals({
        page: currentPage + 1,
        per_page: DEALS_PER_PAGE,
        filter: showCompletedShareExchanges ? FILTER_BY_DONE_STATUS : FILTER_BY_IN_PROGRESS_STATUS,
      }),
    );
  };

  const onScrollExchanges = (event: UIEvent<HTMLElement>) => {
    const { currentTarget } = event;

    if (Math.floor(currentTarget.scrollHeight - currentTarget.scrollTop) === currentTarget.clientHeight) {
      fetchMoreItems();
    }
  };

  useEffect(() => {
    if (!isDealStagesExists) dispatch(getDealStages());

    dispatch(
      getDashboardDeals({
        page: 1,
        per_page: DEALS_PER_PAGE,
        filter: showCompletedShareExchanges ? FILTER_BY_DONE_STATUS : FILTER_BY_IN_PROGRESS_STATUS,
        initialFetch: true,
      }),
    );
  }, [dispatch, showCompletedShareExchanges]);

  return (
    <SnippetLayout className={twMerge('col-start-1 col-end-3 row-start-2 row-end-3 relative', className)}>
      <div className='mb-6'>
        <div className='flex items-center'>
          <Typography className='mr-1' tag='h4' variant={TypographyVariants.MEDIUM}>
            {isMultipleDeals ? SHARE_EXCHANGE_MULTIPLE_SNIPPET_TITLE : SHARE_EXCHANGE_SNIPPET_TITLE}
          </Typography>

          {isLoading && !totalDeals ? (
            <div className='skeleton-loader w-6 h-6 ml-1 mt-1' />
          ) : (
            <div
              className={cn('w-6 h-6 rounded-full bg-green-600 text-l font-bold text-white text-center ml-1 mt-1', {
                'w-8 h-8 leading-8': totalDeals >= 100,
              })}
            >
              {totalDeals}
            </div>
          )}
        </div>
        {isInvestor && (
          <div className='mt-6 md:absolute md:top-5 md:right-7 md:mt-0'>
            <Checkbox
              label='Show Completed Share Exchanges'
              checked={showCompletedShareExchanges}
              onChange={onChangeShowCompletedShareExchanges}
            />
          </div>
        )}
      </div>

      <div
        onScroll={onScrollExchanges}
        className={cn('overflow-auto', { 'h-[315px]': (isLoading && !deals?.length) || !!deals?.length })}
        ref={itemsWrapperRef}
      >
        {checkIsInvestor()
          ? deals && deals.map((deal) => <ShareExchangeInvestorSnippetItem key={deal.id} deal={deal} />)
          : Object.keys(groupedFounderDeals).map((companyName) => (
              <ShareExchangeCompanyGroup key={companyName} companyName={companyName}>
                {groupedFounderDeals[companyName].map((deal) => (
                  <ShareExchangeSnippetItem key={deal.id} deal={deal} />
                ))}
              </ShareExchangeCompanyGroup>
            ))}
        {isLoading && <ShareExchangeSkeleton itemsCount={skeletonItems} />}
      </div>

      {isInvestor && !isLoading && !deals?.length && <ShareExchangeSnippetNoInfo />}
      {!isLoading && !!deals?.length && (
        <Button onClick={onClickViewAll} className='mt-6' variant={ButtonVariants.LINK}>
          View All
        </Button>
      )}
    </SnippetLayout>
  );
};
export default ShareExchangeSnippet;
