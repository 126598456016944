import React, { FC, memo } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { ButtonDropdownActions, UserActionsParamsType, UserData } from 'interfaces';
import { UserOnlineStatusLabel } from 'page-components';
import { ButtonDropdown } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

import UsersRegisteredTooltipContent from './UsersRegisteredTooltipContent';
import UsersTableItemRegisteredData from './UsersTableItemRegisteredData';

type Props = UserData & {
  getActions: (data: UserActionsParamsType) => ButtonDropdownActions[];
  sendInvitation: (id: number) => void;
  disabledInvitation: boolean;
};

const UsersTableItem: FC<Props> = memo(
  ({
    id,
    firstName,
    lastName,
    email,
    phone,
    created_at,
    userType,
    getActions,
    isRegistrationFinished,
    lastActivity,
    online,
    sendInvitation,
    disabledInvitation,
  }) => {
    return (
      <TableRow key={id}>
        <TableData className='pl-1'>
          <ButtonDropdown className='w-40' actions={getActions({ id, firstName, lastName, isRegistrationFinished })} />
        </TableData>
        <TableData>{`${firstName} ${lastName}`}</TableData>
        <TableData>{email}</TableData>
        <TableData>{userType}</TableData>
        <TableData>{phone}</TableData>
        <TableData>{format(parseISO(created_at), DAY_FORMAT_WITH_SLASHES)}</TableData>
        <TableData className='cursor-pointer'>
          <UsersTableItemRegisteredData
            id={id}
            isRegistrationFinished={isRegistrationFinished}
            tooltipContent={
              <UsersRegisteredTooltipContent
                id={id}
                sendInvitation={sendInvitation}
                disabledInvitation={disabledInvitation}
              />
            }
          />
        </TableData>
        <TableData>
          <UserOnlineStatusLabel online={online} lastActivity={lastActivity} />
        </TableData>
      </TableRow>
    );
  },
);

export default UsersTableItem;
UsersTableItem.displayName = 'UsersTableItem';
