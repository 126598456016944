import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import useModal from 'hooks/use-modal/useModal';
import { Founder } from 'interfaces';
import { ButtonDropdown, LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getFounderProfileLink } from 'utils';
import { checkIsAdmin, navigateWithFilter } from 'utils/global/global';

import ConfirmFoundersModal from './confirm-founders-modal/ConfirmFoundersModal';

type Props = {
  founder: Founder;
  loadFounders: VoidFunction;
};

const FoundersTableRow: FC<Props> = ({ founder, loadFounders }) => {
  const navigate = useNavigate();

  const {
    onOpen: onOpenFounderConfirmedCompanies,
    onClose: onCloseFounderConfirmedCompanies,
    ...founderConfirmedCompaniesState
  } = useModal();

  const getFoundersActions = () => [
    {
      title: 'Confirm',
      id: 'confirm',
      action: () => {
        onOpenFounderConfirmedCompanies();
      },
    },
    {
      title: 'Profile',
      id: 'profile',
      action: () => navigate(getFounderProfileLink(founder.id, true)),
    },
  ];

  return (
    <>
      <ConfirmFoundersModal
        founderId={founder.id}
        onClose={onCloseFounderConfirmedCompanies}
        onSuccessUpdateConfirmedCompanies={loadFounders}
        {...founderConfirmedCompaniesState}
      />

      <TableRow>
        <TableData className='pl-1'>
          <ButtonDropdown className='w-40' actions={getFoundersActions()} />
        </TableData>
        <TableData>{founder.firstName}</TableData>
        <TableData>{founder.lastName}</TableData>
        <TableData>{founder.email}</TableData>
        {founder.hasUnconfirmedCompanies ? (
          <TableData className='text-red-500 underline'>Un-Verified</TableData>
        ) : (
          <TableData>Verified</TableData>
        )}
        <TableData>{founder.phone}</TableData>
        <TableData>
          <LinkButton to={navigateWithFilter(checkIsAdmin(), ROUTES.companies, 'founder', founder.id)}>
            Companies
          </LinkButton>
        </TableData>
      </TableRow>
    </>
  );
};

export default FoundersTableRow;
