import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import {
  DUE_DILIGENCE_DOCUMENT_TYPES_LABELS,
  DueDiligenceSections,
  POPULATING_DATA_LOADER_TEXT,
} from 'constants/due-diligence';
import { ShareExchangeStatuses } from 'constants/share-exchange';
import { ConstraintVariants } from 'constants/shared';
import { UserTypes } from 'constants/user';
import { errorNotify } from 'helpers';
import useModal from 'hooks/use-modal/useModal';
import useDueDiligenceBusinessDetails from 'hooks/useDueDiligenceBusinessDetails';
import useDueDiligenceData from 'hooks/useDueDiligenceData';
import useIntervalCheckLock from 'hooks/useDueDiligenceIntervalCheckLock';
import useDueDiligenceMonthsCashRunway from 'hooks/useDueDiligenceMonthsCashRunway';
import useDueDiligencePreventPageLeave from 'hooks/useDueDiligencePreventPageLeave';
import useDueDiligenceValidation from 'hooks/useDueDiligenceValidation';
import useFundraisingDetails from 'hooks/useFundraisingDetails';
import usePreferenceRights from 'hooks/usePreferenceRights';
import useUserActivity from 'hooks/useUserActivity';
import { DueDiligencePersonsFormState } from 'interfaces';
import { selectUserType } from 'modules/current-user/selectors';
import {
  dueDiligenceConfirm,
  dueDiligenceDecline,
  setDueDiligenceDocumentsFilterValue,
  setDueDiligenceEditingSections,
} from 'modules/due-diligence/action';
import {
  selectBusinessName,
  selectDocumentsFilterValue,
  selectDueDiligenceId,
  selectDueDiligenceStatus,
  selectIsDocumentsOrLegalMattersCommentsExists,
  selectIsDueDiligenceCalled,
  selectIsDueDiligenceEditable,
  selectIsDueDiligenceFinishedOrDeclined,
  selectLastCreatedAt,
  selectSignificantControlPersons,
} from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  ApproveDueDiligenceModal,
  BusinessDetailsBlock,
  CompanyIacChooseFundModal,
  CorporateDocumentsAndFinancialsBlock,
  DashboardLayout,
  DeclineDueDiligenceModal,
  DueDiligenceDocumentsModal,
  DueDiligenceHeader,
  DueDiligenceRequestFounderBlock,
  DueDiligenceShareholdersBlock,
  LegalMattersBlock,
  ManagementOwnership,
  MonthsCashRunwayBlock,
  SubmitDueDiligenceBlock,
  UploadDueDiligenceDocumentsModal,
} from 'page-components';
import DueDiligenceActivityModal from 'page-components/due-diligence/due-diligence-activity-modal/DueDiligenceActivityModal';
import FundraisingDetails from 'page-components/due-diligence/fundraising-details/FundraisingDetails';
import PreferenceRights from 'page-components/due-diligence/preference-rights/PreferenceRights';
import SignificantControlBlock from 'page-components/due-diligence/significant-control/SignificantControlBlock';
import { Constraint, FullScreenLoader, ReSubmitButtonWithModal } from 'shared-components';
import { checkIsAdmin } from 'utils';
import { validateDirectorsAddresses } from 'utils/due-diligence';

const DueDiligence = () => {
  const [isConfirmationValidationHighlighted, setIsConfirmationValidationHighlighted] = useState(false);
  const [validateSignificantControlBlock, setValidateSignificantControlBlock] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id, dueDiligenceId: dueDiligenceQueryId } = useParams();
  const dispatch = useAppDispatch();

  const significantControlPersons = useAppSelector(selectSignificantControlPersons);
  const documentsFilterValue = useAppSelector(selectDocumentsFilterValue);
  const isDocumentsOrLegalMattersCommentsExists = useAppSelector(selectIsDocumentsOrLegalMattersCommentsExists);
  const dueDiligenceId = useAppSelector(selectDueDiligenceId);
  const businessName = useAppSelector(selectBusinessName);
  const isDueDiligenceCalled = useAppSelector(selectIsDueDiligenceCalled);
  const isFinishedOrDeclined = useAppSelector(selectIsDueDiligenceFinishedOrDeclined);
  const isEditable = useAppSelector(selectIsDueDiligenceEditable);
  const userType = useAppSelector(selectUserType);
  const lastCreatedAt = useAppSelector(selectLastCreatedAt);
  const dueDiligenceStatus = useAppSelector(selectDueDiligenceStatus);

  const { onOpen: onOpenUploadDocumentsModal, ...uploadDocumentsModalState } = useModal();
  const { isOpen: isDocumentsModalOpen, onOpen: onDocumentsModalOpen, onClose: onDocumentsModalClose } = useModal();
  const { onOpen: onOpenCompanyIacFundModal, ...companyIacModalState } = useModal();
  const { onOpen: onOpenApproveDueDiligenceModal, ...approveDueDiligenceModalState } = useModal();
  const { onOpen: onOpenDeclineDueDiligenceModal, ...declineDueDiligenceModalState } = useModal();
  const {
    onOpen: onOpenCheckActivityModal,
    onClose: onCloseCheckActivityModal,
    isOpen: isOpenCheckActivityModal,
  } = useModal();

  // Logic with preventing page leave
  useDueDiligencePreventPageLeave();

  const { isDueDiligenceLoaded, isDueDiligenceLoading, isDueDiligenceReSubmitted, onPopulateData } =
    useDueDiligenceData();
  const { isLocked, status: checkLockStatus } = useIntervalCheckLock(dueDiligenceId, isDueDiligenceLoaded);

  // Checking user activity
  useUserActivity(onOpenCheckActivityModal, isLocked);

  const businessDetailsProps = useDueDiligenceBusinessDetails({
    isCalled: isDueDiligenceCalled,
    isReSubmitted: isDueDiligenceReSubmitted,
  });

  const { trigger, formState, ...directorsFormMethods } = useForm<DueDiligencePersonsFormState>({
    mode: 'onBlur',
  });
  const { trigger: beneficialOwnersTrigger, ...beneficialOwnersFormMethods } = useForm<DueDiligencePersonsFormState>({
    mode: 'onBlur',
  });

  const isDueDiligenceArchived = dueDiligenceStatus === ShareExchangeStatuses.ARCHIVED;

  const isDueDiligenceEditable = !isLocked && isEditable && !isFinishedOrDeclined && !isDueDiligenceArchived;

  const formattedLastCreatedAt = lastCreatedAt ? format(parseISO(lastCreatedAt), DAY_FORMAT_WITH_SLASHES) : null;

  const monthsCashRunwayProps = useDueDiligenceMonthsCashRunway();
  const fundraisingDetailsProps = useFundraisingDetails();
  const preferenceRightsProps = usePreferenceRights();

  const { unfieldValues, getUnfieldValue } = useDueDiligenceValidation(
    isConfirmationValidationHighlighted,
    isDueDiligenceReSubmitted,
  );

  const isAdmin = useMemo(() => checkIsAdmin(), []);
  const isFmUser = userType === UserTypes.FM_USER;

  const handleOpenSubmittingModal = (isDeclining?: boolean) => {
    if (isDeclining) {
      onOpenDeclineDueDiligenceModal();
    } else {
      dispatch(setDueDiligenceEditingSections(DueDiligenceSections.SIGNIFICANT_CONTROL_PERSONS));
      dispatch(setDueDiligenceEditingSections(DueDiligenceSections.BENEFICIAL_OWNERS));

      onOpenApproveDueDiligenceModal();
    }
  };

  const onDueDiligenceApprove = async () => {
    if (!dueDiligenceId) return;

    const directorsAddressesError = validateDirectorsAddresses(significantControlPersons);

    // This error may happen when admin bypassed validation for Significant Control Persons
    // And entered all form data, but selected wrong addresses ranges
    if (directorsAddressesError) {
      errorNotify(directorsAddressesError);
      setIsConfirmationValidationHighlighted(true);
      approveDueDiligenceModalState.onClose();
      return;
    }

    if (unfieldValues.length > 0) {
      errorNotify('Required fields are not completed for Due Diligence.');
      setIsConfirmationValidationHighlighted(true);
      approveDueDiligenceModalState.onClose();
    } else {
      setIsLoading(true);

      dispatch(dueDiligenceConfirm({ id: dueDiligenceId, isAdmin }))
        .unwrap()
        .then(() => {
          if (!isAdmin) return;

          onOpenCompanyIacFundModal();
        })
        .finally(() => {
          setIsLoading(false);
          approveDueDiligenceModalState.onClose();
          setIsConfirmationValidationHighlighted(false);
        });
    }
  };

  const onDueDiligenceDecline = (reason: string) => {
    if (!dueDiligenceId) {
      return;
    }

    setIsLoading(true);
    dispatch(dueDiligenceDecline({ dueDiligenceId, reason }))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
        declineDueDiligenceModalState.onClose();
      });
  };

  const handleCloseDocumentsModal = () => {
    onDocumentsModalClose();
    dispatch(setDueDiligenceDocumentsFilterValue(''));
  };

  useEffect(() => {
    if (documentsFilterValue) onDocumentsModalOpen();
  }, [documentsFilterValue, onDocumentsModalOpen]);

  return (
    <>
      <DueDiligenceActivityModal isOpen={isOpenCheckActivityModal} onClose={onCloseCheckActivityModal} />

      <CompanyIacChooseFundModal
        companyName={businessName}
        companyId={parseInt(id || '', 10)}
        {...companyIacModalState}
      />

      <UploadDueDiligenceDocumentsModal
        documentLabels={DUE_DILIGENCE_DOCUMENT_TYPES_LABELS}
        dueDiligenceId={dueDiligenceId?.toString()}
        {...uploadDocumentsModalState}
      />

      <DueDiligenceDocumentsModal
        id={id}
        isOpen={isDocumentsModalOpen}
        onClose={handleCloseDocumentsModal}
        filterValue={documentsFilterValue}
      />

      <ApproveDueDiligenceModal
        {...approveDueDiligenceModalState}
        isLoading={isLoading}
        onApproveDueDiligence={onDueDiligenceApprove}
      />

      <DeclineDueDiligenceModal
        {...declineDueDiligenceModalState}
        isLoading={isLoading}
        onDeclineDueDiligence={onDueDiligenceDecline}
      />

      <DashboardLayout>
        <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
          <DueDiligenceHeader
            handleOpenDocumentsModal={onDocumentsModalOpen}
            isLocked={isLocked}
            checkLockStatus={checkLockStatus}
            isFmUser={isFmUser}
            companyId={id}
            lastUpdated={formattedLastCreatedAt}
            headerNode={
              !dueDiligenceQueryId && (
                <ReSubmitButtonWithModal
                  isLocked={isLocked || dueDiligenceStatus !== ShareExchangeStatuses.FINISHED}
                  userType={userType}
                  lastUpdated={formattedLastCreatedAt}
                  onResubmit={onPopulateData}
                />
              )
            }
          />
          <div className='space-y-6'>
            <div
              className={cn('space-y-6', {
                '[&_input]:border-2 inputs-thick-border': false,
              })}
            >
              <BusinessDetailsBlock
                {...businessDetailsProps}
                getUnfieldValue={getUnfieldValue}
                isEditing={isDueDiligenceEditable}
              />
              <FormProvider formState={formState} trigger={trigger} {...directorsFormMethods}>
                <SignificantControlBlock
                  isCalled={isDueDiligenceCalled}
                  validateBlock={validateSignificantControlBlock || !isAdmin}
                  setValidateBlock={setValidateSignificantControlBlock}
                  isEditing={isDueDiligenceEditable}
                  getUnfieldValue={getUnfieldValue}
                  isConfirmationValidationHighlighted={isConfirmationValidationHighlighted}
                  businessDetailsProps={businessDetailsProps}
                />
              </FormProvider>
            </div>
            <FormProvider trigger={beneficialOwnersTrigger} {...beneficialOwnersFormMethods}>
              <DueDiligenceShareholdersBlock
                isCalled={isDueDiligenceCalled}
                isEditing={isDueDiligenceEditable}
                getUnfieldValue={getUnfieldValue}
              />
            </FormProvider>
            <div
              className={cn('flex flex-col 2xl:flex-row space-y-6 2xl:space-y-0 2xl:space-x-6', {
                '2xl:flex-col 2xl:space-x-0 2xl:space-y-6': isDocumentsOrLegalMattersCommentsExists,
              })}
            >
              <CorporateDocumentsAndFinancialsBlock
                shouldBeHighlighted={isConfirmationValidationHighlighted}
                onOpenUploadDocumentModal={onOpenUploadDocumentsModal}
                isEditing={isDueDiligenceEditable}
                className='2xl:basis-1/2'
                getUnfieldValue={getUnfieldValue}
              />

              <LegalMattersBlock
                isConfirmationValidationHighlighted={isConfirmationValidationHighlighted}
                onOpenUploadDocumentModal={onOpenUploadDocumentsModal}
                className='2xl:basis-1/2'
                isEditing={isDueDiligenceEditable}
              />
            </div>
            <MonthsCashRunwayBlock
              {...monthsCashRunwayProps}
              isEditing={isDueDiligenceEditable}
              getUnfieldValue={getUnfieldValue}
            />
            <ManagementOwnership isEditing={isDueDiligenceEditable} />
            <FundraisingDetails
              {...fundraisingDetailsProps}
              getUnfieldValue={getUnfieldValue}
              isConfirmationValidationHighlighted={isConfirmationValidationHighlighted}
              isEditing={isDueDiligenceEditable || fundraisingDetailsProps.isEditing}
            />
            <PreferenceRights
              {...preferenceRightsProps}
              getUnfieldValue={getUnfieldValue}
              isEditing={isDueDiligenceEditable || preferenceRightsProps.isEditing}
            />
            {userType !== UserTypes.FM_USER && !isDueDiligenceArchived && (
              <div className='flex flex-col 2xl:flex-row space-y-6 2xl:space-y-0 2xl:space-x-6'>
                <DueDiligenceRequestFounderBlock
                  isAdmin={isAdmin}
                  dueDiligenceId={dueDiligenceId}
                  isDueDiligenceLoaded={isDueDiligenceLoaded}
                  className='2xl:basis-1/2'
                />

                <SubmitDueDiligenceBlock
                  className='2xl:basis-1/2'
                  onSubmit={handleOpenSubmittingModal}
                  isAdmin={isAdmin}
                />
              </div>
            )}
          </div>
        </Constraint>
      </DashboardLayout>
      {(!isDueDiligenceLoaded || isDueDiligenceLoading) && <FullScreenLoader text={POPULATING_DATA_LOADER_TEXT} />}
    </>
  );
};

export default DueDiligence;
