import React, { FC, useMemo } from 'react';

import { DEAL_DETAILS_PAGE_OUTSTANDING_TITLE, DEAL_STATUSES, SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { Deal } from 'interfaces';
import { selectDealDetailsDocumentsUrl, selectInvestorDealStages } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { DealsDetailsOutstandingTasksItem } from 'page-components';
import { SnippetLayout } from 'shared-components';
import { formatStepsProgressData, getKeyValue } from 'utils';
import { checkIsCompletedStepAndReadyToCompleteStage } from 'utils/deals';

import DealsDetailsOutstandingTasksSkeleton from './DealsDetailsOutstandingTasksSkeleton';

interface Props extends Pick<Deal, 'stage' | 'status'> {
  isLoading?: boolean;
  isAppropriatenessQuestionnaireSigned?: number;
  pendingByQuery?: boolean;
  kycValidTime?: string | null;
}

const DealsDetailsOutstandingTasksSnippet: FC<Props> = ({
  stage,
  status,
  isLoading,
  isAppropriatenessQuestionnaireSigned,
  pendingByQuery,
  kycValidTime,
}) => {
  const documentsUrl = useAppSelector(selectDealDetailsDocumentsUrl);
  const stages = useAppSelector(selectInvestorDealStages);

  // If the step is approval we need to show 'application_form' stage as current step
  const investorStage = stage === SHARE_EXCHANGE_STAGES.APPROVAL ? SHARE_EXCHANGE_STAGES.APPLICATION_FORM : stage;

  const isPendingCurrentStage =
    (stage === SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC && !!isAppropriatenessQuestionnaireSigned) || pendingByQuery;

  const passedStagesKeys = useMemo(() => {
    const currentStageIndex = stages.findIndex((i) => i.stage === investorStage);

    const sliceLastIndex = currentStageIndex === stages?.length - 1 ? stages?.length : currentStageIndex;

    return stages.slice(0, sliceLastIndex === -1 ? stages?.length : sliceLastIndex).map(({ stage }) => stage);
  }, [investorStage, stages]);

  const { steps, currentStep } = useMemo(() => formatStepsProgressData(stages, investorStage), [investorStage, stages]);

  if (isLoading) return <DealsDetailsOutstandingTasksSkeleton />;

  return (
    <SnippetLayout
      className='basis-full shrink mt-6'
      childrenClassName='mt-0'
      title={DEAL_DETAILS_PAGE_OUTSTANDING_TITLE}
    >
      <div>
        {steps
          .filter((step) =>
            currentStep === null
              ? (step.documentKey && documentsUrl?.hasOwnProperty(step.documentKey)) ||
                checkIsCompletedStepAndReadyToCompleteStage(step.key, stage)
              : true,
          )
          .map((task) => (
            <DealsDetailsOutstandingTasksItem
              {...task}
              key={task.key}
              currentStage={stage}
              stageKey={task.key}
              isCurrentStage={task.key === stage || checkIsCompletedStepAndReadyToCompleteStage(task.key, stage)}
              isPassedTask={passedStagesKeys.includes(task.key)}
              passedDocumentUrl={getKeyValue<keyof object, string>(String(task.documentKey))(documentsUrl as never)}
              documentUrl={documentsUrl?.currentDoc}
              stoppedDeal={status === DEAL_STATUSES.STOPPED}
              isPendingCurrentStage={isPendingCurrentStage}
              isAppropriatenessQuestionnaireSigned={isAppropriatenessQuestionnaireSigned}
              kycValidTime={kycValidTime}
            />
          ))}
      </div>
    </SnippetLayout>
  );
};

export default DealsDetailsOutstandingTasksSnippet;
