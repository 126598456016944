import { createSelector } from '@reduxjs/toolkit';

import {
  DUE_DILIGENCE_REQUIRED_CONFIRMATION_DOCUMENTS_TYPES_KEYS,
  DueDiligenceSections,
} from 'constants/due-diligence';
import { ShareExchangeStatuses } from 'constants/share-exchange';
import { BusinessDetails } from 'interfaces';

import { RootState } from '../store';

const selectState = (x: RootState) => x.dueDiligenceReducer;

export const selectDueDiligenceRegisteredOffice = createSelector(
  selectState,
  ({ businessDetails }) => businessDetails?.registeredOffice,
);

export const selectDueDiligenceCompanyId = createSelector(selectState, ({ companyId }) => companyId);

export const selectDueDiligenceId = createSelector(selectState, ({ businessDetails }) => businessDetails?.id);

export const selectRawBusinessDetails = createSelector(selectState, ({ businessDetails }) => businessDetails);

export const selectBusinessDetails = createSelector(
  selectState,
  ({ businessDetails }) =>
    ({
      ...businessDetails,
      ...(businessDetails?.approvalForSecondarySale && {
        approvalForSecondarySale: {
          value: businessDetails?.approvalForSecondarySale,
          label: businessDetails?.approvalForSecondarySale,
        },
      }),
    } as BusinessDetails),
);

export const selectSignificantControlPersons = createSelector(
  selectState,
  ({ significantControlPersons }) => significantControlPersons,
);

export const selectIsBusinessDetailsExist = createSelector(selectState, ({ businessDetails }) => {
  if (businessDetails === null) {
    return false;
  }

  return Object.keys(businessDetails).filter((key) => key !== 'businessName').length !== 0;
});

export const selectIsBusinessDetailsDisabled = createSelector(
  selectState,
  ({ businessDetails }) => businessDetails && Object.values(businessDetails).filter((i) => !i)?.length === 1,
);

export const selectBusinessName = createSelector(selectState, ({ businessDetails }) => businessDetails?.businessName);

export const selectDebtPreferenceRights = createSelector(selectState, ({ businessDetails }) => ({
  id: businessDetails?.id,
  debtPreferenceRights: businessDetails?.debtPreferenceRights,
}));

export const selectDueDiligenceDocuments = createSelector(
  selectState,
  ({ documents: { list, isLoading, totalPages, total } }) => ({
    list,
    isLoading,
    totalPages,
    total,
  }),
);

export const selectBeneficialOwners = createSelector(selectState, ({ beneficialOwners }) => beneficialOwners);

export const selectDocumentsFilterValue = createSelector(selectState, ({ documents }) => documents.filterValue);

export const selectDueDiligenceDocumentTypes = createSelector(selectState, ({ documentTypes }) => documentTypes);

export const selectLegalMatters = createSelector(selectState, ({ legalMatters }) => legalMatters);

export const selectCommentsByType = (type: DueDiligenceSections) =>
  createSelector(selectState, ({ comments }) => comments?.[type]);

export const selectIsDocumentsOrLegalMattersCommentsExists = createSelector(
  selectState,
  ({ comments: { legalMatters, documentTypes } }) => Boolean(documentTypes?.length || legalMatters?.length),
);

export const selectIsLegalMattersHasVicariousLiability = createSelector(
  selectState,
  ({ legalMatters }) => legalMatters?.hasVicariousLiability,
);

export const selectIsDocumentsTypesRequiredKeysTicked = createSelector(selectState, ({ documentTypes }) => {
  return (
    documentTypes?.filter(
      (key) =>
        DUE_DILIGENCE_REQUIRED_CONFIRMATION_DOCUMENTS_TYPES_KEYS.includes(key.documentType) && key.isDocumentAttached,
    )?.length === DUE_DILIGENCE_REQUIRED_CONFIRMATION_DOCUMENTS_TYPES_KEYS.length
  );
});

export const selectDueDiligenceEditingSections = createSelector(selectState, ({ editingSections }) => editingSections);

export const selectIsSignificantControlPersonsExists = createSelector(
  selectState,
  ({ significantControlPersons }) => significantControlPersons?.length,
);

export const selectCompanyRunway = createSelector(selectState, ({ businessDetails, companyRunway }) => ({
  id: businessDetails?.id,
  companyRunway: companyRunway || {},
}));

export const selectFundraisingDetails = createSelector(selectState, ({ businessDetails, fundraisingDetails }) => ({
  id: businessDetails?.id,
  fundraisingDetails: fundraisingDetails || {},
}));

export const selectFoundersOwnershipPercentage = createSelector(selectState, ({ businessDetails }) => ({
  id: businessDetails?.id,
  foundersOwnershipPercentage: businessDetails?.foundersOwnershipPercentage,
}));

export const selectDueDiligenceStatus = createSelector(selectState, ({ businessDetails }) => businessDetails?.status);

export const selectIsDueDiligenceArchived = createSelector(
  selectState,
  ({ businessDetails }) => businessDetails?.status === ShareExchangeStatuses.ARCHIVED,
);

export const selectDueDiligenceCompanyStatus = createSelector(
  selectState,
  ({ businessDetails }) => businessDetails?.companyStatus,
);

export const selectDueDiligenceDeclineReason = createSelector(
  selectState,
  ({ businessDetails }) => businessDetails?.declineReason,
);

export const selectLastCreatedAt = createSelector(selectState, ({ lastCreatedAt }) => lastCreatedAt);

export const selectDueDiligenceFoundersForInvite = createSelector(
  selectState,
  ({ foundersForInvite }) => foundersForInvite,
);

export const selectIsDueDiligenceCalled = createSelector(selectState, ({ isCalled }) => isCalled);

export const selectIsDueDiligenceFinishedOrDeclined = createSelector(
  selectState,
  ({ businessDetails }) =>
    businessDetails?.status === ShareExchangeStatuses.FINISHED ||
    businessDetails?.status === ShareExchangeStatuses.DECLINED,
);

export const selectParentDueDiligenceStatusFinishedOrDeclined = createSelector(
  selectState,
  ({ businessDetails }) =>
    businessDetails?.parentStatus === ShareExchangeStatuses.FINISHED ||
    businessDetails?.parentStatus === ShareExchangeStatuses.DECLINED,
);

export const selectIsDueDiligenceEditable = createSelector(selectState, ({ isEditable }) => isEditable);

export const selectHighlightUnsavedChanges = createSelector(
  selectState,
  ({ highlightUnsavedChanges }) => highlightUnsavedChanges,
);

export const selectChildrenDueDiligence = createSelector(selectState, ({ children }) => children);

export const selectIsChildrenPersonsExists = createSelector(selectState, ({ children }) =>
  Boolean(children[0] && children[0]?.significantControlPersons?.length),
);

export const IsSCPSSameAsInSCCChecked = createSelector(
  selectState,
  ({ businessDetails }) => businessDetails?.isSCPsSameAsInSCC,
);

export const selectCanViewInvestmentProposal = createSelector(
  selectState,
  ({ canViewInvestmentProposal }) => canViewInvestmentProposal,
);
