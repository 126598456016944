import React, { useMemo } from 'react';

import { format } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { updateIsWhitelisted } from 'modules/companies/action';
import { selectCurrentCompanyMainInfo, selectIsCompanyCanBeWhitelisted } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { LabelWithValue, LinkButton, ToggleSwitch } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getAdminCompanyIacLink, getDueDiligenceLink, getNatureOfBusinessList } from 'utils';

import AdminCompanyProfileHeaderPreloader from './AdminCompanyProfileHeaderPreloader';

const COMPANY_PROFILE_DETAILS_INFO_ITEM_CLASSNAME = 'flex flex-col [&>span]:mt-4 [&>span]:text-violet-600 w-fit mr-2';

const AdminCompanyProfileHeader = () => {
  const dispatch = useAppDispatch();
  const {
    companyName,
    dateIncorporated,
    industry,
    size,
    natureOfBusiness,
    isLoading,
    companyNumber,
    isWhitelisted,
    id,
    canStartDueDiligence,
    canViewDueDiligence,
    canViewInvestmentProposal,
    qqUnderRejected,
  } = useAppSelector(selectCurrentCompanyMainInfo);

  const canCompanyBeWhitelisted = useAppSelector(selectIsCompanyCanBeWhitelisted);

  const natureOfBusinessList = useMemo(() => {
    return getNatureOfBusinessList(natureOfBusiness || []);
  }, [natureOfBusiness]);

  const formattedDateIncorporated = dateIncorporated ? format(new Date(dateIncorporated), DAY_FORMAT_WITH_SLASHES) : '';

  const handleToggle = (isToggle: boolean) => {
    if (id) {
      dispatch(updateIsWhitelisted({ id, isWhitelisted: isToggle }));
    }
  };

  if (isLoading) return <AdminCompanyProfileHeaderPreloader />;

  return (
    <div className='md:pl-7.5'>
      <div className='flex flex-col 2xl:flex-row'>
        <div className='mr-24'>
          <Typography className='whitespace-nowrap' tag='h2' variant={TypographyVariants.BODY_SMALL}>
            Company Profile
          </Typography>
          <Typography variant={TypographyVariants.SEMI_LARGE}>
            {companyName} ({companyNumber})
          </Typography>
        </div>
        <div className='flex mt-6 flex-col lg:flex-row gap-4 lg:gap-0 2xl:mt-0'>
          <LabelWithValue className={COMPANY_PROFILE_DETAILS_INFO_ITEM_CLASSNAME} title='Industry' content={industry} />
          <LabelWithValue
            className={COMPANY_PROFILE_DETAILS_INFO_ITEM_CLASSNAME}
            title='Date Incorporated'
            content={formattedDateIncorporated}
          />
          <LabelWithValue
            className={COMPANY_PROFILE_DETAILS_INFO_ITEM_CLASSNAME}
            title='Size of Company'
            content={size}
          />
          <LabelWithValue
            className={COMPANY_PROFILE_DETAILS_INFO_ITEM_CLASSNAME}
            title='Nature of business (SIC)'
            content={natureOfBusinessList}
          />
          <div className='flex justify-start lg:justify-center ml-4'>
            <div>
              <Typography variant={TypographyVariants.MEDIUM} className='font-bold mt-[-5px]'>
                Whitelist
              </Typography>
              <Typography className='mt-4'>Whitelist the company</Typography>
            </div>
            <div className='flex items-center ml-2'>
              <ToggleSwitch disabled={!canCompanyBeWhitelisted} onToggle={handleToggle} initial={isWhitelisted} />
            </div>
          </div>
        </div>
      </div>
      {id && (
        <div className='flex flex-col xs:flex-row gap-4 xs:gap-0 xs:space-x-6 mt-6'>
          <LinkButton
            className='w-full sm:w-fit'
            variant={ButtonVariants.SECONDARY}
            disabled={!canStartDueDiligence && !canViewDueDiligence}
            to={getDueDiligenceLink(id, true)}
            state={canStartDueDiligence ? { shownInvitationProposal: true } : undefined}
          >
            {canStartDueDiligence ? 'Start Due Diligence' : 'Due Diligence'}
          </LinkButton>
          <LinkButton
            className='w-full sm:w-100'
            variant={ButtonVariants.SECONDARY}
            disabled={!canViewInvestmentProposal}
            to={getAdminCompanyIacLink(id)}
          >
            IAC
          </LinkButton>
        </div>
      )}
      {qqUnderRejected && <Typography className='text-red-500 font-bold mt-5'>{qqUnderRejected}</Typography>}
    </div>
  );
};

export default AdminCompanyProfileHeader;
