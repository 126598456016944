import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import qs from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import {
  DEAL_DETAILS_INCORRECT_URL_PATTERN,
  DEAL_STATUSES,
  dealStatusesLabel,
  SHARE_EXCHANGE_STAGES,
  SHARE_EXCHANGE_STAGES_LABELS,
} from 'constants/deals';
import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { getDeclineShareExchangeModalFields, SHARE_EXCHANGE_STOP_FORM_LABEL } from 'constants/share-exchange';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import useDeleteDeal from 'hooks/deals/useDeleteDeal';
import useRestartDeal from 'hooks/deals/useRestartDeal';
import useModal from 'hooks/use-modal/useModal';
import { selectIsAdmin, selectUserEntityData } from 'modules/current-user/selectors';
import { getDealDetails, getDealDetailsByPlainlyId, getDealStages, stopDealById } from 'modules/deals/action';
import {
  selectDealDetails,
  selectDealParametersId,
  selectDealStages,
  selectDealStagesLength,
  selectInvestorDealStages,
} from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  DashboardLayout,
  DealsDetailsOutstandingTasksSnippet,
  DealsDetailsStepsSnippet,
  ShareExchangeDetailsModal,
} from 'page-components';
import ConfirmStartNewShareExchangeModal from 'page-components/deals/deals-details/ConfirmStartNewShareExchangeModal';
import DealDetailsSkeleton from 'page-components/deals/deals-details/DealDetailsSkeleton';
import PlainlyDealDetailsModal from 'page-components/deals/deals-details/PlainlyDealDetailsModal';
import SharesOverviewSnippet from 'page-components/deals/deals-details/SharesOverviewSnippet';
import StopShareExchangesModal from 'page-components/deals/share-exchanges/StopShareExchangesModal';
import {
  AlertModal,
  Button,
  ColoredLabel,
  Constraint,
  FullScreenLoader,
  LabelWithValue,
  TitleWithDescription,
} from 'shared-components';
import Typography from 'shared-components/Typography';
import {
  checkIsAdmin,
  getDealParametersLink,
  getLinkWithTabId,
  getStopShareExchangeModalTitle,
  getTitleWithReplacedYourWord,
  navigateByRole,
} from 'utils';
import {
  checkIsCurrentDocumentCorrect,
  checkIsDealDeleted,
  checkIsDealNotCompleted,
  getStorageDealsStagesLabels,
} from 'utils/deals';

import { useOpenDealsFundInfoModal } from '../hooks/useOpenDealsFundInfoModal';
import { ShareExchangeFeeSnippet } from '../page-components/deals/ShareExchangeFeeSnippet';

const DEAL_DETAILS_INFO_ITEM_CLASSNAME = 'text-secondary leading-5 py-2 mb-[-10px]';

const ONE_MINUTE_IN_MS = 60000;
const FIFTEEN_SECONDS_IN_MS = 15000;

const DealDetails = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { plainly_id: queryPlainlyId, document: queryCurrentDocument, nextStage: queryNextStage } = qs.parse(search);

  const dispatch = useAppDispatch();
  const dealDetails = useAppSelector(selectDealDetails);
  const dealStagesLength = useAppSelector(selectDealStagesLength);
  const stages = useAppSelector(selectDealStages);
  const isAdmin = useAppSelector(selectIsAdmin);
  const dealParametersId = useAppSelector(selectDealParametersId);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const { stage, status, dealExchanges, companyId } = dealDetails || {};
  const { currentDoc } = dealDetails?.documentsUrls || {};

  const [isLoading, setIsLoading] = useState(false);
  const [isScreenLocked, setIsScreenLocked] = useState(false);

  const { onOpen: onOpenStopModal, ...stopModalState } = useModal();
  const { onOpen: onOpenPlainlyModal, onClose: onClosePlainlyModal, isOpen: isOpenPlainlyModal } = useModal();

  const { isOpen: isOpenDetailsModal, onClose: onCloseDetailsModal, onOpen: onOpenDetailsModal } = useModal();
  const { onOpen: handleOpenConfirmStartExchangeModal, ...confirmStartNewExchangeModalProps } = useModal();

  const [handleRestartDeal, { onOpen: handleOpenRestartModal, ...restartModalState }, isRestartDealLoading] =
    useRestartDeal({ onSuccess: () => '' });

  const [handleDeleteDeal, { onOpen: handleOpenDeleteDealModal, ...deleteDealModalProps }, isDeleteDealLoading] =
    useDeleteDeal({
      onSuccess: isAdmin
        ? () =>
            navigate(
              dealParametersId
                ? getLinkWithTabId(getDealParametersLink(dealParametersId, true), 'share-exchanges')
                : navigateByRole(true, ROUTES.deals),
              // Need for deal parameters filter by company id
              {
                state: {
                  companyId: dealDetails?.companyId,
                },
              },
            )
        : handleOpenConfirmStartExchangeModal,
    });

  const [{ isOpen: isDealsFundInfoModalOpen, onClose: onCloseFundInfoModal }, handleHideDealFundInfoModal] =
    useOpenDealsFundInfoModal({
      stage: dealDetails?.stage,
    });

  const [storageDealsStagesLabels, { stageLabel: parsedStageLabel }] = getStorageDealsStagesLabels(dealDetails?.id);

  const currentStepIndex = stage ? stages.findIndex((i) => i.stage === stage) : null;

  const isDealDeleted = status ? checkIsDealDeleted(status) : false;

  const isIncorrectCurrentDoc = !currentDoc || currentDoc?.endsWith(DEAL_DETAILS_INCORRECT_URL_PATTERN);

  const isScreenLockedForKYCStage =
    stage !== SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC ||
    (stage === SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC && isIncorrectCurrentDoc);

  const dealDetailsTitle = `Deal: ${dealDetails?.companyName}`;

  const isKYCStageSigned =
    Boolean(
      dealDetails?.stage === SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC &&
        dealDetails?.isAppropriatenessQuestionnaireSigned,
    ) ||
    (queryCurrentDocument === SHARE_EXCHANGE_STAGES_LABELS.APPROPRIATENESS_KYC &&
      queryNextStage === SHARE_EXCHANGE_STAGES_LABELS.APPLICATION_FORM &&
      dealDetails?.stage !== SHARE_EXCHANGE_STAGES.APPLICATION_FORM);

  const dealDetailsModalCurrentDocument = useMemo(() => {
    if (isKYCStageSigned) return SHARE_EXCHANGE_STAGES_LABELS.APPROPRIATENESS_KYC;

    if (
      !queryPlainlyId &&
      !parsedStageLabel &&
      stage === SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT &&
      !checkIsCurrentDocumentCorrect(currentDoc)
    )
      return SHARE_EXCHANGE_STAGES_LABELS.INTRODUCER_AGREEMENT;

    if (!queryPlainlyId) return parsedStageLabel;

    return queryCurrentDocument as string;
  }, [currentDoc, isKYCStageSigned, queryCurrentDocument, queryPlainlyId, stage, parsedStageLabel]);

  const isDealNotCompleted = checkIsDealNotCompleted(stage, status);

  const handleClosePlainlyModal = () => {
    if (!dealDetails?.id) return;

    onClosePlainlyModal();

    if (
      !queryCurrentDocument ||
      (!parsedStageLabel &&
        stage === SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT &&
        !checkIsCurrentDocumentCorrect(currentDoc))
    )
      return;

    if (isScreenLockedForKYCStage || parsedStageLabel) setIsScreenLocked(true);
  };

  const handleStopDeal = ({ reason }: { reason?: string }) => {
    if (!id) return;

    setIsLoading(true);
    dispatch(stopDealById({ id, isAdmin, stopReason: reason }))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
        stopModalState.onClose();
      });
  };

  const handleLoadDealDetailsByPlainlyKey = () => {
    setIsLoading(true);
    if (!dealStagesLength) dispatch(getDealStages());
    dispatch(getDealDetailsByPlainlyId(queryPlainlyId as string))
      .unwrap()
      .then(({ stageLabel, documentsUrls, id: dealDetailsId }) => {
        // We determine whether the stage has been updated if we were redirected from Plainly
        const isStageUpdated = stageLabel !== queryCurrentDocument;

        const shouldSkipModal = stageLabel === SHARE_EXCHANGE_STAGES_LABELS.APPROVAL;

        const isDocumentUrlUpdated = !documentsUrls.currentDoc?.endsWith(DEAL_DETAILS_INCORRECT_URL_PATTERN);

        if (!isScreenLocked && (!isStageUpdated || !isDocumentUrlUpdated) && !shouldSkipModal) {
          onOpenPlainlyModal();

          localStorage.setItem(
            LocalStorageKeys.DEALS_STAGES_LABELS,
            JSON.stringify({
              ...storageDealsStagesLabels,
              [dealDetailsId]: JSON.stringify({ stageLabel, nextStage: queryNextStage }),
            }),
          );
        }

        if (shouldSkipModal || (isScreenLocked && isStageUpdated && isDocumentUrlUpdated)) setIsScreenLocked(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleLoadDealDetailsById = () => {
    if (!id) {
      return;
    }

    setIsLoading(true);

    dispatch(getDealStages());
    dispatch(getDealDetails({ id, isAdmin: checkIsAdmin() }))
      .unwrap()
      .then(({ id: dealDetailsId, stageLabel, isAppropriatenessQuestionnaireSigned }) => {
        // is deal on review - for /deals/:id page
        const [_, { stageLabel: storageDealStageLabel }] = getStorageDealsStagesLabels(dealDetailsId);

        const shouldOpenModalByStorageStatus =
          !queryPlainlyId && dealDetailsId ? storageDealStageLabel === stageLabel : false;
        // if storage deal status isn't actual - clear this value from local storage
        if (!shouldOpenModalByStorageStatus && dealDetailsId) {
          localStorage.setItem(
            LocalStorageKeys.DEALS_STAGES_LABELS,
            JSON.stringify({ ...storageDealsStagesLabels, [dealDetailsId]: '' }),
          );
          setIsScreenLocked(false);
        }

        if (shouldOpenModalByStorageStatus && !isOpenDetailsModal && !isScreenLocked) {
          onOpenPlainlyModal();
          return;
        }

        if (
          stage === SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC &&
          (queryCurrentDocument || isAppropriatenessQuestionnaireSigned)
        )
          onOpenPlainlyModal();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (queryPlainlyId) {
      handleLoadDealDetailsByPlainlyKey();
      return;
    }

    handleLoadDealDetailsById();
  }, [dispatch, id, queryPlainlyId]);

  useEffect(() => {
    const { currentDoc } = dealDetails?.documentsUrls || {};

    // If stage is 'Introducer Agreement' and there is no current document url, reload page after 1 minute
    // Because connection to Plainly may take some time, and we need to wait when document url will be ready
    if (
      dealDetails?.stage !== SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT ||
      checkIsCurrentDocumentCorrect(currentDoc)
    ) {
      return undefined;
    }

    onOpenPlainlyModal();

    const timeout = setTimeout(() => {
      location.reload();
    }, ONE_MINUTE_IN_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, [dealDetails?.stage, dealDetails?.documentsUrls]);

  // If the information is not synchronized and the screen is locked, we make a request every minute to receive information about deal
  useEffect(() => {
    if (!isScreenLocked) return undefined;

    const interval = setInterval(
      queryPlainlyId ? handleLoadDealDetailsByPlainlyKey : handleLoadDealDetailsById,
      FIFTEEN_SECONDS_IN_MS,
    );

    return () => {
      clearInterval(interval);
    };
  }, [isScreenLocked]);

  if (!dealDetails || (!queryPlainlyId && dealDetails?.id !== Number(id))) return <DealDetailsSkeleton />;

  return (
    <>
      {!isAdmin && (
        <AlertModal
          isLoading={false}
          title='The Fund Information Memorandum and Key Investor Information Document for Daxia can be found on your dashboard'
          onSubmit={() => handleHideDealFundInfoModal(dealDetails?.id)}
          onClose={onCloseFundInfoModal}
          submitButtonText='Confirm'
          isOpen={isDealsFundInfoModalOpen}
        />
      )}

      <ConfirmStartNewShareExchangeModal companyId={companyId} {...confirmStartNewExchangeModalProps} />
      <ShareExchangeDetailsModal isLoading={isLoading} isOpen={isOpenDetailsModal} onClose={onCloseDetailsModal} />
      <PlainlyDealDetailsModal
        isOpen={isOpenPlainlyModal && !isAdmin}
        onClose={handleClosePlainlyModal}
        currentDocument={dealDetailsModalCurrentDocument}
        isQueryDocument={queryCurrentDocument || parsedStageLabel}
      />
      <AlertModal
        onSubmit={() => handleDeleteDeal(dealDetails?.id)}
        isLoading={isDeleteDealLoading}
        title='Are you sure you want to delete this Deal?'
        titleVariant={TypographyVariants.LARGE}
        submitButtonText='Confirm'
        modalClassName='min-w-[70%] 2xl:min-w-[1048px]'
        hideIcon
        {...deleteDealModalProps}
      />

      <AlertModal
        onSubmit={() => handleRestartDeal(dealDetails?.id)}
        isLoading={isRestartDealLoading}
        title='Are you sure you want to re-start this Deal?'
        submitButtonText='Confirm'
        modalClassName='lg:w-8/12 xl:w-6/12'
        contentClassName='lg:px-28 lg:py-12'
        wrapperModalClassName='lg:py-0 lg:px-0'
        {...restartModalState}
      />

      <DashboardLayout>
        <StopShareExchangesModal
          selectedDeals={[dealDetails]}
          selectedDealsIds={[dealDetails.id]}
          handleStopShareExchange={handleStopDeal}
          isActionLoading={isLoading}
          fields={getDeclineShareExchangeModalFields(
            !isAdmin ? getTitleWithReplacedYourWord(SHARE_EXCHANGE_STOP_FORM_LABEL, entityName, isTypeEntity) : '',
          )}
          title={!isAdmin ? getStopShareExchangeModalTitle(dealDetails?.companyName) : ''}
          doubleConfirmation={!isAdmin}
          {...stopModalState}
        />
        <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
          <div className='flex justify-between items-start flex-col sm:flex-row gap-4 sm:gap-0'>
            <div className='flex flex-col xs:flex-row gap-2 xs:gap-0'>
              <TitleWithDescription
                titleVariant={TypographyVariants.SEMI_LARGE}
                title={dealDetailsTitle}
                description={
                  <div className='mt-4'>
                    {isAdmin && (
                      <Typography className={DEAL_DETAILS_INFO_ITEM_CLASSNAME}>
                        Investor Name: <span className='text-grey-800'>{dealDetails.investor}</span>
                      </Typography>
                    )}
                    <Typography className={DEAL_DETAILS_INFO_ITEM_CLASSNAME}>
                      Company Number: <span className='text-grey-800'>{dealDetails.companyNumber}</span>
                    </Typography>
                    <Typography className={DEAL_DETAILS_INFO_ITEM_CLASSNAME}>
                      Initiated:{' '}
                      <span className='text-grey-800'>
                        {format(parseISO(dealDetails.created_at), DAY_FORMAT_WITH_SLASHES)}
                      </span>
                    </Typography>
                    <LabelWithValue
                      className='text-red-500 [&>span]:font-normal [&>span]:text-blue-800 [&>span]:ml-1 w-full'
                      title='Stop Reason'
                      content={dealDetails?.stopReason}
                    />
                    {isAdmin && dealDetails?.stoppedAt && (
                      <LabelWithValue
                        className='text-red-500 [&>span]:font-normal [&>span]:text-blue-800 [&>span]:ml-1 w-full mt-1'
                        title='Stopped: '
                        content={format(new Date(dealDetails.stoppedAt), DAY_FORMAT_WITH_SLASHES)}
                      />
                    )}
                  </div>
                }
              />
              <ColoredLabel className='mt-3 xs:ml-6' {...dealStatusesLabel[dealDetails.status]} />
            </div>
            <div
              className={cn('flex w-full sm:w-auto', {
                'pr-0': isAdmin,
                'flex-col items-end 1xl:items-start 1xl:flex-row': !isAdmin,
              })}
            >
              {!isAdmin && !isDealDeleted && (
                <div className='w-full sm:w-auto'>
                  <Button
                    disabled={isKYCStageSigned}
                    type='button'
                    onClick={onOpenDetailsModal}
                    className='w-full sm:w-44 mb-4 sm:ml-6 1xl:mb-0'
                  >
                    View Details
                  </Button>
                </div>
              )}
              {isDealNotCompleted && !isDealDeleted && (
                <div className='flex justify-end flex-col items-end 1xl:items-center 1xl:flex-row'>
                  {isAdmin && status === DEAL_STATUSES.STOPPED && (
                    <Button className='ml-4' onClick={handleOpenRestartModal}>
                      Re-start
                    </Button>
                  )}

                  {isAdmin && (
                    <Button
                      className='w-fit mt-4 1xl:mt-0 ml-4'
                      onClick={handleOpenDeleteDealModal}
                      variant={ButtonVariants.PRIMARY}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-x-8'>
            {isAdmin ? (
              <>
                <DealsDetailsStepsSnippet
                  stage={dealDetails?.stage}
                  closingDate={dealDetails?.closingDate}
                  documentsUrls={dealDetails?.documentsUrls}
                />
                <SharesOverviewSnippet
                  shareExchanges={dealExchanges}
                  originalInvestmentPrice={dealDetails?.originalInvestmentPrice}
                />
                <ShareExchangeFeeSnippet
                  id={dealDetails?.id}
                  fee={{ feeRate: dealDetails?.feeRate, feeMinimum: dealDetails?.feeMinimum }}
                  currentStepIndex={currentStepIndex}
                />
              </>
            ) : (
              <DealsDetailsOutstandingTasksSnippet
                isLoading={isLoading}
                stage={dealDetails?.stage}
                status={dealDetails?.status}
                isAppropriatenessQuestionnaireSigned={dealDetails?.isAppropriatenessQuestionnaireSigned}
                pendingByQuery={isKYCStageSigned}
                kycValidTime={dealDetails?.kycValidTime}
              />
            )}
            {status !== DEAL_STATUSES.STOPPED && isDealNotCompleted && (
              <div className='w-full flex justify-end mt-6'>
                <Button
                  disabled={!isAdmin && isKYCStageSigned}
                  className='w-fit'
                  onClick={onOpenStopModal}
                  variant={ButtonVariants.SECONDARY}
                >
                  Stop Share Exchange
                </Button>
              </div>
            )}
          </div>
        </Constraint>
      </DashboardLayout>
      {isScreenLocked &&
        (isScreenLockedForKYCStage || (parsedStageLabel && stage !== SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC)) && (
          <FullScreenLoader
            text={getTitleWithReplacedYourWord('Please wait while we process your document', entityName, isTypeEntity)}
          />
        )}
    </>
  );
};

export default DealDetails;
