import React, { FC } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Checkbox } from 'shared-components';
import Typography from 'shared-components/Typography';

interface Props {
  className?: string;
  id: number;
  firstName: string;
  lastName: string;
  handleSetSelectedUsers: (id: number) => void;
  checked: boolean;
  companyName?: string;
  isRegistrationFinished?: boolean;
  isTypeEntity?: boolean;
  invited?: boolean;
}

const InvitingFundModalFmUsersItem: FC<Props> = ({
  id,
  firstName,
  lastName,
  className,
  handleSetSelectedUsers,
  checked,
  companyName,
  isRegistrationFinished,
  isTypeEntity,
  invited,
}) => (
  <div
    className={twMerge(
      cn(
        'flex justify-between items-center border border-b-0 border-grey-300 rounded py-3 px-1.5 text-grey-500',
        className,
        { 'bg-grey-300/30 border-grey-300': invited },
      ),
    )}
  >
    <Typography>
      {isTypeEntity ? companyName : `${firstName} ${lastName} `}
      {!isRegistrationFinished && <span className='text-violet-500'>[Un-confirmed User]</span>}
    </Typography>
    <Checkbox
      checked={checked}
      disabled={invited}
      inputClassName='top-0'
      onChange={invited ? undefined : () => handleSetSelectedUsers(id)}
    />
  </div>
);

export default InvitingFundModalFmUsersItem;
