import React from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import { Deal } from 'interfaces';
import { StepProgress } from 'shared-components/step-progress';
import Typography from 'shared-components/Typography';

type Props = {
  deal: Deal;
};

const ShareExchangeSnippetItem = ({ deal }: Props) => {
  const itemTitle = 'Investor Name';
  const itemName = deal.investor;

  return (
    <div className='w-full my-2 border border-grey-300 px-8 py-5 flex justify-between'>
      <div className='flex w-[90%]'>
        <div className='w-3/12'>
          <Typography className='mb-2' variant={TypographyVariants.BODY_SMALL}>
            {itemTitle}
          </Typography>
          <div className='text-lg font-bold'>{itemName}</div>
        </div>
        <StepProgress stage={deal.stage} className='ml-4 w-[65%]' stepClassName='hover:w-max 2xl:hover:w-max' />
      </div>
    </div>
  );
};

export default ShareExchangeSnippetItem;
