import React, { useEffect } from 'react';

import { getWelcomeInvestorShareExchangeData } from 'modules/share-exchange/action';
import { useAppDispatch } from 'modules/store';
import { SnippetLayout } from 'shared-components';

import WelcomeUserContent from './WelcomeUserContent';

const WelcomeUserSnippet = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWelcomeInvestorShareExchangeData());
  }, [dispatch]);

  return (
    <SnippetLayout className='row-start-1 row-end-2 col-start-1 col-end-3'>
      <WelcomeUserContent />
    </SnippetLayout>
  );
};

export default WelcomeUserSnippet;
