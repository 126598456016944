import { DEAL_STATUSES } from 'constants/deals';
import { SHARE_EXCHANGE_STATUSES, COMPANY_WITH_SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import { ShareExchangeStatuses } from 'constants/share-exchange';
import { Option, ChartItem, MetaState } from 'interfaces';

import { ColoredLabelVariants } from '../constants/shared';

export enum InvestmentStatuses {
  INITIAL = 'initial',
  IN_EXCHANGE = 'inExchange',
  EXCHANGED = 'exchanged',
  ACTIVE = 'active',
}

export enum InvestmentsFieldNames {
  CLASS_OF_SHARES = 'classOfShares',
  COMPANY_NUMBER = 'companyNumber',
  TOTAL_SHARES = 'totalShares',
  PRICE_PER_SHARE = 'pricePerShare',
  AMOUNT_INVESTED = 'amountInvested',
  INVESTMENT_DATE = 'investmentDate',
  ORIGINAL_INVESTMENT_PRICE = 'originalInvestmentPrice',
}

export interface InvestmentInput {
  [InvestmentsFieldNames.COMPANY_NUMBER]: Option | string;
  [InvestmentsFieldNames.CLASS_OF_SHARES]: Option | string;
  [InvestmentsFieldNames.TOTAL_SHARES]: string;
  [InvestmentsFieldNames.PRICE_PER_SHARE]: string;
  [InvestmentsFieldNames.AMOUNT_INVESTED]: string;
  [InvestmentsFieldNames.INVESTMENT_DATE]: string;
  [InvestmentsFieldNames.ORIGINAL_INVESTMENT_PRICE]: string;
}

export type InvestmentPayload = Omit<InvestmentInput, 'companyNumber'> & {
  companyNumber: Option;
};

export type EditInvestmentPayload = InvestmentPayload & {
  id: string;
};

export interface InvestmentActiveShareExchange {
  companyId: number;
  id: number;
  status: ShareExchangeStatuses;
}

export interface Investment extends Omit<InvestmentInput, 'companyNumber' | 'classOfShares' | 'totalShares'> {
  id: string;
  companyId: string;
  companyName: string;
  investmentName: string | null;
  companyNumber: string;
  totalShares: number;
  classOfShares: string | null;
  isBlocked: boolean;
  isChild?: boolean;
  isLastByCompany?: boolean;
  amount?: string;
  originalInvestmentPrice: string;
  userCompanySEStatus?: COMPANY_WITH_SHARE_EXCHANGE_STATUSES;
  activeShareExchangeRequest: CompanyActiveShareExchange | null;
}

export interface InvestmentWithCompany {
  id: string;
  investmentName: string | null;
  classOfShares: string;
  investmentDate: string;
  pricePerShare: string;
  totalShares: number;
  amountInvested: string;
  currentInvestmentValue: number;
  companyName?: string;
  companyNumber: string;
  originalInvestmentPrice: string;
  status: InvestmentStatuses | null;
  isBlended?: boolean;
}

export interface CompanyActiveShareExchange {
  companyId: number;
  id: number;
  status: ShareExchangeStatuses;
}

export interface CompanyWithUserShareExchangeStatus {
  userCompanySEStatus: COMPANY_WITH_SHARE_EXCHANGE_STATUSES;
}

export interface CompanyWithInvestments extends CompanyWithUserShareExchangeStatus {
  id: number;
  companyNumber: string;
  companyName: string;
  investments: InvestmentWithCompany[];
  activeShareExchangeRequest: CompanyActiveShareExchange | null;
  shareExchangeRequestId: number;
  isActiveExchange: boolean;
  declineReason: string;
  serDeclined: boolean;
  seStatus: DEAL_STATUSES;
  seId: number;
}

export interface CreateShareExchangeRequestPayload {
  status: SHARE_EXCHANGE_STATUSES;
  id: string;
  companyWhitelisted: boolean;
}

export type HandleClickRequestShareExchangeType = (params: {
  companyId: number;
  userCompanySEStatus: COMPANY_WITH_SHARE_EXCHANGE_STATUSES;
  shareExchangeRequestId: number;
  serDeclined: boolean;
  investmentIds?: number[];
  draftDealId?: number;
}) => void;

export interface ReviewInvestment
  extends Pick<
    Investment,
    | 'id'
    | 'companyName'
    | 'companyNumber'
    | InvestmentsFieldNames.PRICE_PER_SHARE
    | InvestmentsFieldNames.INVESTMENT_DATE
    | InvestmentsFieldNames.ORIGINAL_INVESTMENT_PRICE
  > {
  classOfShares: string;
  totalShares: string;
  amountInvested: string | number;
}

export type InvestmentKeys = (keyof Investment)[];

export interface AddInvestmentPayload extends Omit<InvestmentInput, 'companyNumber'> {
  companyNumber: string;
}

export interface EditInvestmentRequestPayload extends AddInvestmentPayload {
  id: string;
}

export interface GraphData {
  list: ChartItem[];
  isLoading: boolean;
}

export interface QualificationAnswers {
  title: string;
  status?: string;
}

export interface QualificationQuestion {
  id: number;
  title: string;
  answers: QualificationAnswers[];
}

export interface QualificationQuestionCompany {
  id: number;
  companyName: string;
}

export interface QualificationQuestionResponce {
  company: QualificationQuestionCompany;
  data: QualificationQuestion[];
}

export interface QualificationQuestionsState {
  isLoading: boolean;
  list: QualificationQuestion[];
  company: QualificationQuestionCompany;
}

export interface InvestmentsState extends MetaState {
  companiesWithInvestments: CompanyWithInvestments[];
  allInvestments: Investment[];
  reviewInvestments: ReviewInvestment[];
  linkedInInvestments: {
    isLoading: boolean;
    list: Investment[];
  };
  companiesGraph: GraphData;
  industriesGraph: GraphData;
  qualificationQuestions: QualificationQuestionsState;
  isLoading: boolean;
  isActionLoading: boolean;
}

export interface InvestmentSharedValues {
  pricePerShare: string;
  totalShares: number;
  originalInvestmentPrice: string;
  investmentName: string | null;
}

export const investmentStatusesColoredLabel: {
  [key in InvestmentStatuses]: { children: string; variant: ColoredLabelVariants };
} = {
  [InvestmentStatuses.INITIAL]: { children: 'Initial', variant: ColoredLabelVariants.PENDING },
  [InvestmentStatuses.ACTIVE]: { children: 'Active', variant: ColoredLabelVariants.SUCCESS },
  [InvestmentStatuses.IN_EXCHANGE]: { children: 'In Exchange', variant: ColoredLabelVariants.SUCCESS },
  [InvestmentStatuses.EXCHANGED]: { children: 'Exchanged', variant: ColoredLabelVariants.PENDING },
};
