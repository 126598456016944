import { FieldValues, SubmitHandler } from 'react-hook-form';

import {
  DUE_DILIGENCE_DOCUMENTS_TYPES,
  DueDiligenceSections,
  DueDiligenceCompanyStatuses,
} from 'constants/due-diligence';
import { ShareExchangeStatuses } from 'constants/share-exchange';
import { SIGNIFICANT_CONTROL_DOCUMENT_TYPES } from 'constants/significant-control-company';
import { UserTypes } from 'constants/user';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';

import { CompanyNatureOfBusiness } from './companies.interfaces';
import { FileDocument } from './documents.interfaces';
import { DateRange, MetaState } from './global.interfaces';
import { Option, Comment } from './shared.interfaces';
import { TruncatedUser } from './users.interfaces';

export interface BusinessDetails {
  id: number;
  businessName: string;
  registeredNumber: string;
  businessSector: string;
  dateOfIncorporation: string;
  registeredOffice: string;
  phone?: string;
  website?: string;
  declineReason: string | null;
  status: ShareExchangeStatuses;
  groupStructure?: string;
  companyStatus: DueDiligenceCompanyStatuses | null;
  companyType?: string;
  parentStatus?: ShareExchangeStatuses | null;
  approvalForSecondarySale?: Option | null;
  natureOfBusiness?: CompanyNatureOfBusiness[];
  foundersOwnershipPercentage: string;
  noOfMonthsCashRunway: string;
  isSCPsSameAsInSCC?: boolean;
  debtPreferenceRights: string | null;
}

export type BusinessDetailsFieldForEdit = Omit<BusinessDetails, 'natureOfBusiness'>;

export interface DueDiligenceBusinessDetails extends Omit<BusinessDetails, 'approvalForSecondarySale'> {
  natureOfBusiness: CompanyNatureOfBusiness[];
  approvalForSecondarySale?: string;
  id: number;
}

export interface SignificantControlPersonAddress extends DateRange {
  id: number;
  address: string;
  isNew?: boolean;
}

export interface SignificantControlPerson {
  id: number;
  fullName: string;
  dateOfBirth: string | null;
  checked: boolean;
  residentialAddresses: SignificantControlPersonAddress[];
  nominee?: string;
}

export interface SignificantControlPersonAddressInput extends Omit<SignificantControlPersonAddress, 'id'> {
  id?: number;
  personId?: number;
  isNew?: boolean;
}

export interface DueDiligencePersonInput extends Omit<SignificantControlPerson, 'id' | 'residentialAddresses'> {
  id?: number;
  isNew?: boolean;
  residentialAddresses: SignificantControlPersonAddressInput[];
  nominee?: string;
}

export interface DueDiligencePersonsFormState {
  directors: DueDiligencePersonInput[];
  beneficialOwners: DueDiligencePersonInput[];
}

export interface PersonsDeletedAddress {
  id: number;
  addressId: number;
  index: number;
}

export interface SignificantControlDeletePerson {
  id: number;
  significantId: number;
}

export interface DueDiligenceDocuments extends MetaState {
  list: DueDiligenceDocument[] | null;
  isLoading: boolean;
  filterValue: string;
}

export interface DueDiligenceDocumentPayload {
  dueDiligenceId: number;
  documentId: number;
}

export interface DueDiligenceDocument
  extends Pick<FileDocument, 'id' | 'filename' | 'location' | 'fileSize' | 'internalFileName'> {
  documentType: DUE_DILIGENCE_DOCUMENTS_TYPES;
  source: string;
  officialDate: string;
  link: string;
}

export interface DueDiligenceDocumentTypes {
  documentDate?: string | null;
  isDocumentAttached?: boolean;
  id: number;
  documentsCount: number;
  documentType: DUE_DILIGENCE_DOCUMENTS_TYPES & SIGNIFICANT_CONTROL_DOCUMENT_TYPES;
  documents: DueDiligenceDocument[];
}

export interface UpdateDueDiligenceDocumentTypesResponse {
  documentType: DueDiligenceDocumentTypes;
}

export interface DueDiligenceLegalMatters {
  hasVicariousLiability: boolean | undefined;
  isDocumentsAttached: boolean;
  details: string;
}

export interface DueDiligenceLegalMattersPayload extends DueDiligenceLegalMatters {
  dueDiligenceId: number;
}

export interface MonthsCashRunwayInput {
  lastYearRevenue: string;
  currentYearRevenue: string;
  nextYearRevenue: string;
  otherDetails: string;
  cashRunway: string;
}

export interface ManagementOwnershipInput {
  foundersOwnershipPercentage: Option | null;
}

export interface PreferenceRightsInput {
  debtPreferenceRights: string;
}

export type DueDiligenceCommentsState = { [key in DueDiligenceSections]: DueDiligenceComment[] };

export interface FundraisingDetailsInput {
  isCurrentlyFundraising: boolean | undefined;
  otherDetails: string;
  targetAmountForRaise: string;
  totalAmountSoFarReceived: string;
}

export interface DueDiligenceCompanyRunway {
  lastYearRevenue: number;
  currentYearRevenue: number;
  nextYearRevenue: number;
  cashRunway: string;
  otherDetails: string;
}

export interface DueDiligenceFundraisingDetails {
  isCurrentlyFundraising: boolean;
  otherDetails: string;
  targetAmountForRaise: number;
  totalAmountSoFarReceived: number;
}

export type CreateOrUpdateCompanyRunway = Partial<DueDiligenceCompanyRunway> & { id: number };

export type CreateOrUpdateFundraisingDetails = Partial<FundraisingDetailsInput> & { id: number };

export interface DueDiligenceState extends DueDiligenceData {
  documents: DueDiligenceDocuments;
  editingSections: DueDiligenceSections[];
  foundersForInvite: TruncatedUser[];
  isEditable: boolean;
  highlightUnsavedChanges: boolean;
}

export interface DueDiligenceDataWithoutChildren {
  businessDetails: DueDiligenceBusinessDetails | null;
  significantControlPersons: SignificantControlPerson[];
  legalMatters: DueDiligenceLegalMatters | null;
  beneficialOwners: SignificantControlPerson[];
  documentTypes: DueDiligenceDocumentTypes[];
  comments: DueDiligenceCommentsState;
  companyRunway: DueDiligenceCompanyRunway | null;
  fundraisingDetails: DueDiligenceFundraisingDetails | null;
  companyId: number | null;
  lastCreatedAt: string | null;
  resubmitted: boolean;
}

export type DueDiligenceData = DueDiligenceDataWithoutChildren & {
  children: DueDiligenceDataWithoutChildren[];
  isCalled: boolean;
  canViewInvestmentProposal: boolean;
};

export interface DueDiligenceUploadDocumentsInput {
  documentType: Option | null;
  officialDate: string;
  documentName?: string;
}

export interface DueDiligenceUploadDocumentsPayload {
  file: File;
  dueDiligenceId: string;
  documentType: string;
  officialDate: string;
  documentName?: string;
}

export interface DueDiligenceAddCommentPayload {
  dueDiligenceId: number;
  text: string;
  sectionType: DueDiligenceSections;
  type: string;
}

export interface DueDiligencePersonsProps {
  isCalled: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
}

export interface GetDueDiligencePayload {
  companyId: string;
  dueDiligenceId?: string | undefined;
}

export type DueDiligencePersonsSubmitFunctionType = ({
  onOpen,
  handleCancel,
}: {
  onOpen: (params: { directors: DueDiligencePersonInput[] }) => void;
  handleCancel: VoidFunction;
}) => SubmitHandler<FieldValues>;

export type DueDiligenceResourcePath = {
  resourcePath: string;
};

export type DueDiligenceComment = Comment<DueDiligenceSections>;

export type DueDiligenceAddCommentsReducerPayload = { type: DueDiligenceSections; comment: DueDiligenceComment };

export type DueDiligencePersonsFormNames = 'beneficialOwners' | 'directors';

export type DueDiligenceSelectedDocumentTypeFunction = (props?: Record<string, any>) => void;

export enum DueDiligenceDeclineFieldNames {
  REASON_SELECT = 'reasonSelect',
  REASON_TEXT = 'reasonText',
}

export interface GetArchivedDataPayload {
  id: string;
  userType: UserTypes;
}

export interface DueDiligenceDeclineFormState {
  [DueDiligenceDeclineFieldNames.REASON_SELECT]: Option | null;
  [DueDiligenceDeclineFieldNames.REASON_TEXT]: string;
}
