import { InvitePreviewTemplate } from '../interfaces';

export const getMockedCompaniesInviteEmailTemplate = (companyName?: string): InvitePreviewTemplate => ({
  content: '',
  preview: `<!doctype html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="color-scheme" content="light dark">
        <meta name="supported-color-schemes" content="light dark">
    </head>
    <body style="margin: 0;padding: 0;font-family: Arial, sans-serif; background: #f8f8f8">
    <div style="max-width: 650px; background-color: #ffffff; border: none; font-family: Arial, sans-serif; margin:0 auto;">
        <table width="100%" cellpadding="0" cellspacing="0" border="0"
               style="max-width: 650px; background-color: #ffffff; border: none;">
            <tbody>
            <tr>
                <td align="center">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="col-650" width="650">
                        <tbody>
                        <tr>
                            <td align="center" style="background-color: #F1F1F3; padding: 24px 40px;">
        <a href="https://pland.co" style="text-decoration: none;">
            <img src='https://lh3.googleusercontent.com/d/12hKvQegvnw-j47I1dBU7STHOXuSRBbtr'
                 alt='plan' style="display: inline-block; max-height: 35px; margin:0">
            <img src='https://lh3.googleusercontent.com/d/1CrjLoLdSetMBVegdMwtXxKEAawEtqkqX'
                 alt='d'
                 style="display: inline-block; max-height: 35px; margin-left:-4px">
        </a>
    </td>
                        </tr>
                        <tr>
                            <td style="padding: 40px 24px 0 24px;">
                                    <table class="container" role="presentation" border="0" cellpadding="0" cellspacing="0">
            <tr>
                <td></td>
                <td>
                    <div class="content">
                        <div class="maincontent text-darkblue">
    <div style="clear: right"></div>
    <div style="border: 2px solid #000000; padding: 20px 10px;">
        <b>Don"t invest unless you’re prepared to lose all your money invested. This is a high-risk investment. You could lose
            all the money you invest and are unlikely to be protected if something goes wrong. <a href="https://pland.co/risksummary">Take two minutes to learn more</a>.</b>
    </div>
    <br>
    <h1 style="font-size:24px; font-weight:700; line-height: 30px; color: #14120F;">
        Good morning, ${companyName} Shareholder
    </h1>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        planD represent a fund, Daxia Ltd, who would be willing to purchase up to 25% of the shares in ${companyName}, on a first come, first
        served basis. It is important to note that the deal is on an exchange of shares basis - not cash, and you would
        be getting shares in  as consideration. However, we understand that should the price per share be less than the price you initially paid, this could be a loss crystallisation event.
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        Fees apply: the greater of £100 or 1.5% of the initial investment value - e.g. if you invested £10,000 the fee
        would be £150.
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        You can register on the platform here: Once registered, the process will be as follows:
    </p>
    <table cellspacing="0" cellpadding="0" style="margin: 40px auto 30px;">
        <tr>
            <td align="center" style="background-color: #31D3C8; padding: 16px 24px; border-radius: 32px; display:flex; justify-content:center; align-items: center;">
                <a href="" target="_blank"
                   style="color: #080E36; text-decoration: none; font-size:16px; font-weight:700; line-height:24px;">
                    Register
                    <a href="">
                        <img style="vertical-align:middle; padding-left:8px;"
                             src="https://lh3.googleusercontent.com/pw/AP1GczMEH-q_UcOw2NF1R8KXtfr4TZq9keOURGEMF-_W41saYBezOYfZ8W7oMwK2qE3P4yfWz3ygM6E91mWm_2DdiY3apfxqGe0bGLvFfQmzf_MueX5P4r6lLptvkqqu8xKmYu8O2eUgEbSQR-EuDRVCN11P=w24-h24-s-no-gm"
                             alt="arrow" border="0">
                    </a>
                </a>
            </td>
        </tr>
    </table>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        Once registered, the process will be as follows:
    </p>
    
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        1. Add your ${companyName} shareholding details to the "Share Exchange" page - the deal has already
        been approved and is otherwise ready to launch
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        2. Review the Information Memorandum and eSign the Introducer Agreement
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        3. Complete the required Appropriateness Questionnaire (online form - dictated by FCA - with a 24 hours cooling off before step 4)
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        4. Complete the Application Form (auto populated - just requires number of shares)
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        5. eSign the Share Exchange Agreement (SEA)
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        6. Pay planD’s introducer fee
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        7. eSign Stock Transfer Form (STF)
    </p>
    
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        Upon completion of the deal, you will receive an Investment Confirmation Letter from the Fund Manager, which together with the SEA (step 5) shows the price / value for the exit.
        Please note: The availability of any tax relief depends on the individual circumstances of each investor and of the company concerned - you should always obtain independent tax advice.
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        If you have any questions, email planD on support@pland.co or book a meeting.
    </p>
    
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        ---------
    </p>
    <div style="font-style: italic">
        <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        Video Explainer: More information on the planD process can be found in the explainer video <a href="https://vimeo.com/711789041">here</a>.
    </p>
        <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        Daxia: Daxia is a Fund whose sole purpose is to acquire stakes in predominantly underperforming (S)EIS companies in exchange for Shares in itself. This cashless transaction provides the Investor with exposure to a diversified portfolio thereby increasing the chances of liquidity events.
    </p>
        <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        FIFO (first in, first out): The ordinary share identification rules do not apply to shares that have attracted EIS relief. There is no pooling of these shares and other matching rules do not apply. Instead the Income Tax rules in ITA07/S246(2) apply. Disposals are identified first against the earliest acquisition.
    </p>
    </div>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        This is a financial promotion for the purposes of Section 21 of FSMA. It is issued by GetplanD Ltd FRN: 945131, an Appointed Representative of Enterprise Investment Partners LLP FRN:604439, an AIFM as defined by the AIFMD, who have approved it, on 13/10/23, and who are authorised by the FCA. This is not an offer to buy or sell securities and does not constitute a Direct Offer Financial Promotion. Applications for investment may only be made on the basis of the relevant [Information Memorandum and application form, copies of which are available on request. No reliance is to be placed on the information contained in this document in making such an application. Information herein is not intended to, nor should be taken to, constitute advice. This material is directed only at persons in the UK.
    </p>
    <p style="color: #74726F; line-height: 24px; margin-bottom: 28px; font-size: 16px; font-weight: 400;">
        GetplanD Ltd source investments in unquoted securities, which are classified by the FCA as a Non-Readily Realisable Security (NRRS). As such, these products may only be marketed to limited categories of investors relating to knowledge, experience or financial situation. Investors should not invest unless they are prepared to lose all the money they invest. This is a high risk investment. Past performance is not a reliable indicator of future results. Investment is of a long-term and illiquid nature. It can be difficult to value or sell unquoted investments. Any described tax advantages associated with investing are based on current legislation, are subject to change, and depend on the individual circumstances of each investor.
    </p>
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>
        </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="container" style="max-width:650px;background-color:#ffffff;border:none;font-family:Arial,sans-serif; margin:0 auto;">
    </div>
    </body>
    </html>`,
});
