import React, { ReactNode } from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import Typography from 'shared-components/Typography';

interface Props {
  step: number;
  title?: string;
  isActive?: boolean;
  description?: string;
  children?: ReactNode;
}

const StepperItemDetails = ({ step, title, description, children }: Props) => {
  return (
    <div className='mt-4 pl-1'>
      <div>
        <Typography className='text-[10px] text-grey-500 leading-3'>STEP {step}</Typography>
        {title && (
          <Typography
            variant={TypographyVariants.LARGE}
            className='text-2xl text-grey-800 leading-8 mt-1 whitespace-normal'
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography className='text-grey-500 whitespace-normal leading-6 mt-4'>{description}</Typography>
        )}
      </div>
      {children}
    </div>
  );
};

export default StepperItemDetails;
