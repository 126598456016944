import React, { FC, useMemo } from 'react';

import { SHARE_EXCHANGE_OLD_STRUCTURE_PROGRESS_BAR_MESSAGE } from 'constants/share-exchange';
import { selectDealDetailsDocumentsUrl, selectDealStages } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { formatStepsProgressData } from 'utils';

import StepProgressItem from './StepProgressItem';

type Props = {
  stage: string;
  className?: string;
  stepClassName?: string;
  isDeletedOrStopped?: boolean;
};

const SnippetStepProgress: FC<Props> = ({ className, stage, isDeletedOrStopped, stepClassName }) => {
  const stages = useAppSelector(selectDealStages);
  const documentsUrls = useAppSelector(selectDealDetailsDocumentsUrl);

  const { steps, currentStep: currentStepIndex } = useMemo(() => {
    return formatStepsProgressData(stages, stage || '');
  }, [stage, stages]);

  if (currentStepIndex === null)
    return <p className='text-grey-500 text-xl'>{SHARE_EXCHANGE_OLD_STRUCTURE_PROGRESS_BAR_MESSAGE}</p>;

  if (!steps?.length) return <div className='skeleton-loader h-2' />;

  return (
    <div className={className}>
      <div className='w-full flex items-end h-[35px] relative'>
        <div className='absolute top-6.5 w-full h-2 bg-gray1 rounded' />
        {steps.map(({ key, ...step }, index) => (
          <StepProgressItem
            documentsUrls={documentsUrls}
            key={index}
            stageKey={key}
            stepClassName={stepClassName}
            isDeletedOrStopped={isDeletedOrStopped}
            index={index}
            currentStepIndex={currentStepIndex}
            isLastStep={currentStepIndex === steps?.length - 1}
            {...step}
          />
        ))}
      </div>
    </div>
  );
};

export default SnippetStepProgress;
