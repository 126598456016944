import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { adminCompaniesApis, companiesApis, dueDiligenceCheckLockApis, foundersCompanyDueDiligenceApis } from 'apis';
import {
  adminCompanyDueDiligenceApis,
  adminDueDiligenceApis,
  ddAdminApis,
  ddFoundersApis,
  foundersDueDiligenceApis,
} from 'apis/due-diligence.apis';
import { findUserApi } from 'apis/users.apis';
import { DueDiligenceSections, DUE_DILIGENCE_FOUNDER_IS_NOT_INVITED } from 'constants/due-diligence';
import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { errorNotify, showServerError, successNotify } from 'helpers';
import {
  BusinessDetails,
  DueDiligenceAddCommentPayload,
  DueDiligenceData,
  DueDiligenceDocumentPayload,
  DueDiligenceLegalMattersPayload,
  DueDiligenceUploadDocumentsPayload,
  GetListPayload,
  PersonsDeletedAddress,
  SignificantControlPersonAddressInput,
  IdWithMetaPayload,
  DueDiligencePersonInput,
  DueDiligencePersonsFormNames,
  DueDiligenceBusinessDetails,
  DueDiligenceDocumentTypes,
  CreateOrUpdateCompanyRunway,
  CreateOrUpdateFundraisingDetails,
  GetDueDiligencePayload,
  WithIsAdmin,
} from 'interfaces';
import history from 'services/history';
import {
  getDueDiligenceDocumentsRequestByUserType,
  getDueDiligencePersonsResourcePath,
  getDueDiligenceRequestByUserType,
} from 'utils/due-diligence';

export const DUE_DILIGENCE_SLICE_NAME = 'due-diligence';

export const getDueDiligenceData = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/getDueDiligenceData`,
  async ({ companyId, dueDiligenceId }: GetDueDiligencePayload, { dispatch, rejectWithValue }) => {
    const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

    try {
      if (userType === UserTypes.INVESTOR) {
        return null;
      }

      const response = await getDueDiligenceRequestByUserType(userType, companyId, dueDiligenceId);

      dispatch(setDueDiligence({ ...response.data.data, isCalled: true }));

      return null;
    } catch (err: any) {
      // If founder is not invited to due diligence then redirect to dashboard
      dispatch(setDueDiligence({ isCalled: true }));

      if (err.response?.status === 500 && err.response.data.message === 'Access Denied. Founder is not invited.') {
        errorNotify(DUE_DILIGENCE_FOUNDER_IS_NOT_INVITED);
        history.push(ROUTES.dashboard);
      }

      if (userType === UserTypes.FOUNDER && err.response?.status === 403) {
        history.push(ROUTES.myCompanies);
      }

      return rejectWithValue(err);
    }
  },
);

export const getDueDiligenceCheckLock = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/getDueDiligenceCheckLock`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await dueDiligenceCheckLockApis.getDueDiligenceCheckLockData(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getDueDiligenceCompanyNameById = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/getDueDiligenceCompanyNameById`,
  async ({ id, isAdmin }: WithIsAdmin<{ id: string }>, { dispatch, rejectWithValue }) => {
    try {
      const response = await (isAdmin ? adminCompaniesApis.getCompanyName(id) : companiesApis.getCompanyName(id));

      dispatch(
        setDueDiligence({
          businessDetails: { businessName: response.data.data.companyName } as DueDiligenceBusinessDetails,
        }),
      );
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createDueDiligence = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/createDueDiligence`,
  async ({ id, isAdmin }: WithIsAdmin<{ id: string }>, { dispatch, rejectWithValue }) => {
    try {
      const response = await (isAdmin
        ? adminCompanyDueDiligenceApis.createDueDiligence(id)
        : foundersCompanyDueDiligenceApis.createDueDiligence(id));

      dispatch(setDueDiligence(response.data.data));
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getDueDiligenceDocuments = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/getDueDiligenceDocuments`,
  async (payload: GetListPayload & { id: string }, { rejectWithValue }) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const response = await getDueDiligenceDocumentsRequestByUserType(userType, payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteDueDiligenceDocument = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/deleteDueDiligenceDocument`,
  async (payload: DueDiligenceDocumentPayload, { rejectWithValue, dispatch }) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const response = await (userType === UserTypes.ADMIN
        ? adminDueDiligenceApis.deleteDueDiligenceDocument(payload)
        : foundersDueDiligenceApis.deleteDueDiligenceDocument(payload));

      dispatch(updateDueDiligenceDocumentType(response.data.documentType));

      successNotify('The document was successfully deleted');
      return payload.documentId;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const requestDueDiligenceFounderInfo = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/inviteFounder`,
  async ({ id, founderId, isAdmin }: { id: number; founderId: number; isAdmin: boolean }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin
        ? adminDueDiligenceApis.requestFounderInfo({ id, founderId })
        : foundersDueDiligenceApis.requestFounderInfo({ id, founderId }));
      successNotify('The invitation email has been sent');
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const requestFounderIdByEmail = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/findFounderIdByEmail`,
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const response = await findUserApi.findFounderIdByEmail(email);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const uploadDueDiligenceDocument = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/uploadDueDiligenceDocument`,
  async (payload: DueDiligenceUploadDocumentsPayload, { rejectWithValue, dispatch }) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;
      const formData = new FormData();

      formData.append('documentFile', payload.file);
      formData.append('dueDiligenceId', payload.dueDiligenceId);
      formData.append('documentType', payload.documentType);
      formData.append('officialDate', payload.officialDate);

      if (payload?.documentName) {
        formData.append('documentName', payload.documentName);
      }

      const requestPayload = { formData, dueDiligenceId: payload.dueDiligenceId };
      const requestConfig = {
        headers: { 'Content-type': 'multipart/form-data' },
      };

      const response = await (userType === UserTypes.ADMIN
        ? adminDueDiligenceApis.uploadDueDiligenceDocument(requestPayload, requestConfig)
        : foundersDueDiligenceApis.uploadDueDiligenceDocument(requestPayload, requestConfig));

      if (response?.data?.documentType) {
        dispatch(updateDueDiligenceDocumentType(response.data.documentType));
      }

      successNotify('The document was successfully uploaded');

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const editDueDiligenceBusinessDetails = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/editDueDiligenceBusinessDetails`,
  async (
    {
      notificationMessage,
      approvalForSecondarySale,
      ...restValues
    }: Partial<BusinessDetails> & { notificationMessage?: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const payload = {
        ...restValues,
        ...(approvalForSecondarySale && { approvalForSecondarySale: approvalForSecondarySale?.value || '' }),
      };

      const response =
        userType === UserTypes.ADMIN
          ? await adminDueDiligenceApis.editDueDiligenceBusinessDetails(payload)
          : await foundersDueDiligenceApis.editDueDiligenceBusinessDetails(payload);

      dispatch(setDueDiligence({ businessDetails: response.data.data }));

      successNotify(notificationMessage || 'The business details was successfully edited');
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const saveLegalMatters = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/save-legal-matters`,
  async (payload: Partial<DueDiligenceLegalMattersPayload>, { rejectWithValue }) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const response =
        userType === UserTypes.ADMIN
          ? await adminDueDiligenceApis.saveDueDiligenceLegalMatters(payload)
          : await foundersDueDiligenceApis.saveDueDiligenceLegalMatters(payload);

      successNotify('Legal matters were successfully saved');
      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceAddPersons = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceAddPersons`,
  async (
    payload: {
      dueDiligenceId: number;
      persons: Partial<DueDiligencePersonInput>[];
      name: DueDiligencePersonsFormNames;
    },
    { rejectWithValue },
  ) => {
    try {
      const { dueDiligenceId, persons, name } = payload;
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      // First of all we need tto create persons-block with significant control
      const createdSignificantControlPersons = await Promise.all(
        persons.map(({ fullName, checked, dateOfBirth, nominee }) => {
          const corePersonData = { fullName, checked, dateOfBirth };
          const person = !!nominee ? { nominee, ...corePersonData } : corePersonData;

          const payload = { person, id: dueDiligenceId, resourcePath: getDueDiligencePersonsResourcePath(name) };

          return userType === UserTypes.ADMIN
            ? adminDueDiligenceApis.createSignificantControlPerson(payload)
            : foundersDueDiligenceApis.createSignificantControlPerson(payload);
        }),
      );

      await Promise.all(
        createdSignificantControlPersons.reduce(
          (acc: Promise<AxiosResponse<unknown>>[], { data: significantControlPersonResponse }, index) => {
            const { id: significantControlPersonId } = significantControlPersonResponse.data;
            const { residentialAddresses } = persons[index];

            if (!residentialAddresses) {
              return acc;
            }

            // Then we need to create addresses for each person. Take in mind that we person may have multiple addresses
            // if address doesn't have from and to fields we don't need to create it
            const createAddressesRequests = residentialAddresses.map((address) => {
              const payload = {
                address,
                id: significantControlPersonId,
                resourcePath: getDueDiligencePersonsResourcePath(name),
              };

              return userType === UserTypes.ADMIN
                ? ddAdminApis.createSignificantControlPersonAddress(payload)
                : ddFoundersApis.createSignificantControlPersonAddress(payload);
            });

            return [...acc, ...createAddressesRequests];
          },
          [],
        ),
      );

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceDeletePersons = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceDeletePersons`,
  async (
    payload: { id: number; significantIds: number[]; name: DueDiligencePersonsFormNames },
    { rejectWithValue },
  ) => {
    try {
      const { id, significantIds, name } = payload;
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      await Promise.all(
        significantIds.map((significantId) => {
          const payload = { id, significantId, resourcePath: getDueDiligencePersonsResourcePath(name) };
          return userType === UserTypes.ADMIN
            ? adminDueDiligenceApis.deleteSignificantControlPerson(payload)
            : foundersDueDiligenceApis.deleteSignificantControlPerson(payload);
        }),
      );

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceUpdatePersons = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceUpdatePersons`,
  async (
    payload: { id: number; name: DueDiligencePersonsFormNames; persons: Partial<DueDiligencePersonInput>[] },
    { rejectWithValue },
  ) => {
    try {
      const { id, persons, name } = payload;
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      await Promise.all(
        persons.map((person) => {
          const payload = { dueDiligenceId: id, resourcePath: getDueDiligencePersonsResourcePath(name), ...person };
          return userType === UserTypes.ADMIN
            ? adminDueDiligenceApis.updateSignificantControlPerson(payload)
            : foundersDueDiligenceApis.updateSignificantControlPerson(payload);
        }),
      );

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceUpdateAddresses = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceUpdateAddresses`,
  async (
    payload: { id: number; name: DueDiligencePersonsFormNames; addresses: SignificantControlPersonAddressInput[] },
    { rejectWithValue },
  ) => {
    try {
      const { id, addresses, name } = payload;
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      if (addresses?.length)
        await Promise.all(
          addresses.map((address) => {
            const payload = { id, address, resourcePath: getDueDiligencePersonsResourcePath(name) };
            return userType === UserTypes.ADMIN
              ? ddAdminApis.updateSignificantControlPersonAddress(payload)
              : ddFoundersApis.updateSignificantControlPersonAddress(payload);
          }),
        );

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceAddNewAddresses = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceAddNewAddresses`,
  async (
    { addresses, name }: { addresses: SignificantControlPersonAddressInput[]; name: DueDiligencePersonsFormNames },
    { rejectWithValue },
  ) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      await Promise.all(
        addresses.map(({ personId, ...address }) => {
          const payload = { id: personId || 0, address, resourcePath: getDueDiligencePersonsResourcePath(name) };
          return userType === UserTypes.ADMIN
            ? ddAdminApis.createSignificantControlPersonAddress(payload)
            : ddFoundersApis.createSignificantControlPersonAddress(payload);
        }),
      );

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceDeleteAddresses = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceDeleteAddresses`,
  async (
    { addresses, name }: { addresses: PersonsDeletedAddress[]; name: DueDiligencePersonsFormNames },
    { rejectWithValue },
  ) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      await Promise.all(
        addresses.map((data) => {
          const payload = { ...data, resourcePath: getDueDiligencePersonsResourcePath(name) };

          return userType === UserTypes.ADMIN
            ? ddAdminApis.deleteSignificantControlPersonAddress(payload)
            : ddFoundersApis.deleteSignificantControlPersonAddress(payload);
        }),
      );

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceAddComment = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceAddComment`,
  async ({ sectionType, ...payload }: DueDiligenceAddCommentPayload, { rejectWithValue }) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const response =
        userType === UserTypes.ADMIN
          ? await adminDueDiligenceApis.addDueDiligenceComment(payload)
          : await foundersDueDiligenceApis.addDueDiligenceComment(payload);
      successNotify('The comment was successfully added');
      return { type: sectionType, comment: response.data.data };
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceConfirm = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceConfirm`,
  async ({ id, isAdmin }: { id: number; isAdmin: boolean }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin
        ? adminDueDiligenceApis.confirmDueDiligence(id)
        : foundersDueDiligenceApis.confirmDueDiligence(id));
      successNotify('The DD page was successfully submitted');

      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const dueDiligenceDecline = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/dueDiligenceDecline`,
  async (payload: { dueDiligenceId: number; reason: string }, { rejectWithValue }) => {
    try {
      const response = await adminDueDiligenceApis.rejectDueDiligence(payload);
      successNotify('The DD page was declined');

      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getDueDiligenceFoundersForInvite = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/getDueDiligenceFoundersForInvite`,
  async ({ isAdmin, ...payload }: IdWithMetaPayload & { isAdmin: boolean }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin
        ? adminDueDiligenceApis.getDueDiligenceFoundersReadyForInvite(payload)
        : foundersDueDiligenceApis.getDueDiligenceFoundersReadyForInvite(payload));
      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createOrUpdateCompanyRunway = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/createOrUpdateCompanyRunway`,
  async (payload: CreateOrUpdateCompanyRunway, { rejectWithValue, dispatch }) => {
    // TODO - change payload type to INPUT
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const response = await (userType === UserTypes.ADMIN
        ? adminDueDiligenceApis.createOrUpdateCompanyRunway(payload)
        : foundersDueDiligenceApis.createOrUpdateCompanyRunway(payload));

      dispatch(setDueDiligence({ companyRunway: response.data.data }));

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createOrUpdateFundraisingDetails = createAsyncThunk(
  `${DUE_DILIGENCE_SLICE_NAME}/createOrUpdateFundraisingDetails`,
  async (payload: CreateOrUpdateFundraisingDetails, { rejectWithValue, dispatch }) => {
    try {
      const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

      const response = await (userType === UserTypes.ADMIN
        ? adminDueDiligenceApis.createOrUpdateFundraisingDetails(payload)
        : foundersDueDiligenceApis.createOrUpdateFundraisingDetails(payload));

      dispatch(setDueDiligence({ fundraisingDetails: response.data.data }));

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const setDueDiligence = createAction<Partial<DueDiligenceData>>('SET_DUE_DILIGENCE');

export const setDueDiligenceHighlightUnsavedChanges = createAction<boolean>('SET_HIGHLIGHTED_UNSAVED_CHANGES');

export const setDueDiligenceDocumentsFilterValue = createAction<string>('SET_CURRENT_FILTER_VALUE');

export const setDueDiligenceEditingSections = createAction<DueDiligenceSections>('SET_EDITING_DUE_DILIGENCE_SECTION');

export const removeDueDiligenceEditingSections = createAction<DueDiligenceSections>(
  'REMOVE_EDITING_DUE_DILIGENCE_SECTION',
);

export const updateDueDiligenceDocumentType = createAction<DueDiligenceDocumentTypes>(
  'UPDATE_DUE_DILIGENCE_DOCUMENT_TYPE',
);
