import { MONTH_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { LocalStorageKeys } from 'constants/global';
import { DAXIA_PATTERN } from 'constants/investments';
import { parseDateStringToUnix } from 'helpers';
import {
  AddInvestmentPayload,
  CompanyWithInvestments,
  Investment,
  InvestmentPayload,
  InvestmentSharedValues,
  InvestmentStatuses,
  InvestmentWithCompany,
  StartShareExchangeWatchValues,
} from 'interfaces';

import { formatFloatNumbers } from './global/global';

// The function is needed for grouping, sorting by "investmentDate" and adding additional fields for correct display in the table.

// 1. The function sorts investments by "investmentDate"
// 2. Group by the key "companyName", keeping the order of investments after sorting
// 3. Add fields to investments: "isChild", "isLastCompany"

// The key "isChild" - indicates whether the investment is a child;
// The key "isLastByCompany" - indicates whether the company is the last in a certain group

export const groupAndConvertInvestments = (investments: Investment[]): Investment[] => {
  const groupedInvestments = [...investments]
    .sort(
      (a, b) =>
        parseDateStringToUnix(b.investmentDate, MONTH_FORMAT_WITH_SLASHES) -
        parseDateStringToUnix(a.investmentDate, MONTH_FORMAT_WITH_SLASHES),
    )
    .map(
      ((groups: { [key: string]: Investment[] }) => (investment: Investment) => {
        const { companyName } = investment;

        if (groups[companyName]) {
          groups[companyName].push(investment);
          return [];
        }
        return (groups[companyName] = [investment]);
      })({}),
    )
    .flat();

  return groupedInvestments.map((investment, index) => {
    const { companyName, pricePerShare } = investment;

    const nextInvestment = groupedInvestments[index + 1];
    const prevInvestment = groupedInvestments[index - 1];

    const isChild = Boolean(companyName === prevInvestment?.companyName);
    const isLastByCompany = nextInvestment?.companyName !== companyName;
    return {
      ...investment,
      pricePerShare: formatFloatNumbers(pricePerShare),
      isChild,
      isLastByCompany,
    };
  });
};

export const formatInvestmentFormData = (data: InvestmentPayload): AddInvestmentPayload => {
  const { companyNumber, ...rest } = data;

  return {
    companyNumber: companyNumber.value,
    ...rest,
  };
};

export const calculateNoOfExchangesShares = ({
  noOfShares,
  companyPrice,
  fundSharePrice,
}: StartShareExchangeWatchValues) => {
  const calculatedValue = Math.floor(Number(noOfShares) * (Number(companyPrice) / Number(fundSharePrice)));

  return isFinite(calculatedValue) ? calculatedValue.toString() : '0';
};

export const getParsedWelcomeModalUsersIds = () => {
  const usersIds = localStorage.getItem(LocalStorageKeys.HIDE_INVESTOR_WELCOME_MODAL_USERS_IDS) || '';
  return usersIds ? JSON.parse(usersIds) : [];
};

export const removeIdFromWelcomeModalUsersIds = (selfId?: number) => {
  if (!selfId) return;
  const parsedIds = getParsedWelcomeModalUsersIds();

  localStorage.setItem(
    LocalStorageKeys.HIDE_INVESTOR_WELCOME_MODAL_USERS_IDS,
    JSON.stringify(parsedIds?.filter((userId: number) => userId !== selfId)),
  );
};

export const addUserIdToWelcomeModalUsersIds = (selfId?: number) => {
  if (!selfId) return;

  const parsedIds = getParsedWelcomeModalUsersIds();

  if (parsedIds?.includes(selfId)) return;

  localStorage.setItem(LocalStorageKeys.HIDE_INVESTOR_WELCOME_MODAL_USERS_IDS, JSON.stringify([...parsedIds, selfId]));
};

export const filterCompanyWithInvestmentsByInvestmentStatus = (
  companiesWithInvestments: CompanyWithInvestments[],
  investmentStatus: InvestmentStatuses,
  positiveCondition?: boolean,
) => {
  if (!companiesWithInvestments) return [];

  return companiesWithInvestments
    .map(({ investments, ...companyWithInvestment }) => ({
      ...companyWithInvestment,
      investments: investments.filter(({ status }) =>
        positiveCondition ? status === investmentStatus : status !== investmentStatus,
      ),
    }))
    .filter(({ investments }) => investments.length);
};

export const getFilteredInvestmentsByInvestmentName = (investments: Investment[], filterValue: string) =>
  investments.filter((investment) => investment.investmentName === filterValue);

// Filter investments by investmentName
export const getFilteredCompanyWithInvestmentsByInvestmentName = (
  companiesWithInvestments: CompanyWithInvestments[],
  filterValue: string,
) =>
  companiesWithInvestments.flatMap((company) =>
    company.investments.filter((investment) => investment.investmentName === filterValue),
  );

// get 1 investment with calculations
export const getFinalInvestmentWithCalculations = <T extends InvestmentSharedValues>(daxiaInvestments: T[]) => {
  return daxiaInvestments.reduce(
    (acc, investment, index) => {
      const { pricePerShare, totalShares } = investment;
      if (index === 0)
        return {
          ...investment,
          originalInvestmentPrice: String(totalShares * parseFloat(pricePerShare)),
        };

      return {
        ...acc,
        originalInvestmentPrice: String(
          parseFloat(acc.originalInvestmentPrice) + totalShares * parseFloat(pricePerShare),
        ),
        totalShares: (acc.totalShares || 0) + totalShares,
        pricePerShare: String(
          (parseFloat(acc.pricePerShare) + parseFloat(pricePerShare)) /
            (index === daxiaInvestments?.length - 1 ? daxiaInvestments?.length : 1), // Blended pricePerShare
        ),
        isBlended: true,
      };
    },
    {
      originalInvestmentPrice: '',
    } as T,
  );
};

// get filtered and calculated investment
export const getFinalDaxiaInvestment = (companiesWithInvestments: CompanyWithInvestments[]) => {
  const filteredByDaxiaPatternInvestments = getFilteredCompanyWithInvestmentsByInvestmentName(
    companiesWithInvestments,
    DAXIA_PATTERN,
  );

  return getFinalInvestmentWithCalculations(filteredByDaxiaPatternInvestments);
};

export const getFormattedAllInvestmentsIncludedDaxia = (investments: Investment[]): Investment[] => {
  const firstDaxiaInvestmentIndex = investments.findIndex(({ investmentName }) =>
    investmentName?.includes(DAXIA_PATTERN),
  );

  if (firstDaxiaInvestmentIndex === -1) return investments;

  const filteredInvestments = getFilteredInvestmentsByInvestmentName(investments, DAXIA_PATTERN);

  const finalDaxiaInvestment = getFinalInvestmentWithCalculations<Investment>(filteredInvestments);

  return investments
    .map((investment, index) => {
      if (index === firstDaxiaInvestmentIndex) return finalDaxiaInvestment;

      return investment.investmentName?.includes(DAXIA_PATTERN) ? null : investment;
    })
    .filter((investment) => investment) as Investment[];
};

export const replaceCompanyWithDaxiaInvestments = (
  companiesWithInvestments: CompanyWithInvestments[],
  replaceInvestment: InvestmentWithCompany,
): CompanyWithInvestments[] => {
  let isReplaced = false;

  return companiesWithInvestments.map(({ investments, ...company }) => ({
    ...company,
    investments: investments?.length
      ? investments
          ?.map((investment) => {
            const isDaxiaInvestment = investment.investmentName?.includes(DAXIA_PATTERN);

            if (isReplaced && isDaxiaInvestment) return null;

            if (!isReplaced && isDaxiaInvestment) {
              isReplaced = true;
              return replaceInvestment;
            }

            return investment;
          })
          .filter((investment) => investment)
      : [],
  })) as CompanyWithInvestments[];
};

// get companiesWithInvestments with replacedInvestments
export const getFormattedCompanyWithInvestmentsWithDaxia = (
  companiesWithInvestments: CompanyWithInvestments[],
): CompanyWithInvestments[] => {
  const finalDaxiaInvestment = getFinalDaxiaInvestment(companiesWithInvestments);

  return replaceCompanyWithDaxiaInvestments(companiesWithInvestments, finalDaxiaInvestment);
};

export const getCompaniesInvestmentsWithFormattedNumbers = (data: CompanyWithInvestments[]) =>
  data?.map(({ investments, ...company }) => {
    const investmentsWithFormattedNumbers = investments.map(({ amountInvested, pricePerShare, ...investment }) => ({
      amountInvested: formatFloatNumbers(amountInvested),
      pricePerShare: formatFloatNumbers(pricePerShare),
      ...investment,
    }));

    return { ...company, investments: investmentsWithFormattedNumbers };
  });
