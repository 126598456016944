import React from 'react';

import { COMPANIES_IAC_SNIPPET_COLUMNS } from 'constants/companies-iac';
import { CompanyIac } from 'interfaces';
import Table from 'shared-components/table';
import TableContentWrapper from 'shared-components/table/TableContentWrapper';
import TableNoData from 'shared-components/table/TableNoData';

import CompaniesIacShortInfoTableItem from './CompaniesIacShortInfoTableItem';

interface Props {
  companiesIac?: CompanyIac[] | null;
  isLoading?: boolean;
}

const CompaniesIacShortInfoTable = ({ companiesIac, isLoading }: Props) => (
  <Table columns={COMPANIES_IAC_SNIPPET_COLUMNS}>
    <TableContentWrapper
      isLoading={isLoading}
      data={companiesIac}
      renderContent={(data) => <CompaniesIacShortInfoTableItem key={data.id} data={data} />}
      noDataNode={<TableNoData text='No Companies Iac Found' skipColumns={1} />}
      columns={COMPANIES_IAC_SNIPPET_COLUMNS?.length}
      rows={3}
    />
  </Table>
);

export default CompaniesIacShortInfoTable;
