import React from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { DealDocumentsUrlsType, StepProgressItem as StepProgressItemType } from 'interfaces';
import { openUrlInSeparateTab } from 'utils';

const STEP_CLASSNAMES = 'pl-1 pr-6 mb-3 text-gray font-medium truncate';

interface Props extends Omit<StepProgressItemType, 'key'> {
  stageKey: string;
  stepClassName?: string;
  isDeletedOrStopped?: boolean;
  documentsUrls?: DealDocumentsUrlsType;
  currentStepIndex: number;
  index: number;
  isLastStep?: boolean;
}

const StepProgressItem = ({
  className,
  isCompleted,
  filled,
  text,
  hidden,
  documentKey,
  stepClassName,
  isDeletedOrStopped,
  documentsUrls,
  index,
  currentStepIndex,
  isLastStep,
}: Props) => {
  const isCurrentStep = index === currentStepIndex;

  return (
    <div
      className={cn(
        className,
        'grow w-[10%] cursor-pointer transition-all duration-500 hover:w-[35%] hover:xl:w-[25%] hover:2xl:w-[15%]',
        stepClassName,
        { 'grow-0 w-[0%] hover:w-[0%]': hidden },
      )}
      onClick={() => {
        const documentLink = documentsUrls && documentKey ? documentsUrls[documentKey] : null;

        if (!documentLink || (isDeletedOrStopped && isCurrentStep)) return;

        openUrlInSeparateTab(documentLink);
      }}
    >
      <p
        className={twMerge(
          cn(STEP_CLASSNAMES, {
            'text-yellow-500': isCurrentStep,
            'text-green-500': index < currentStepIndex || isLastStep,
            'text-cherry': isDeletedOrStopped && isCurrentStep,
          }),
        )}
      >
        {text}
      </p>
      <div
        className={cn('relative mr-1 h-2 border border-gray2 rounded', {
          'mr-0': isLastStep,
        })}
      >
        <div
          className={twMerge(
            cn('bg-green-500 rounded h-full', filled, {
              'bg-transparent': !isCompleted,
              'bg-yellow-500': isCurrentStep,
              'bg-green-500 w-full': isLastStep,
              'bg-cherry': isDeletedOrStopped && isCurrentStep,
            }),
          )}
        />
      </div>
    </div>
  );
};

export default StepProgressItem;
