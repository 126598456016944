import React, { FC } from 'react';

import cn from 'classnames';
import { format, isValid } from 'date-fns';
import { toDate } from 'date-fns-tz';
import DatePicker from 'react-date-picker';
import { DatePickerProps } from 'react-date-picker/dist/esm/DatePicker';
import { Control, Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import {
  DAY_FORMAT_WITH_SLASHES,
  MONTH_FORMAT_WITH_SLASHES,
  YEAR_DAY_MONTH_FORMAT_WITH_DASH,
  YEAR_MONTH_FORMAT_WITH_DASH,
} from 'constants/dateFormats';
import { DATEPICKER_MAX_DETAIL, FieldTypes } from 'constants/shared';
import { FormItem, FromItemHigligtedColor } from 'interfaces';
import HighlightedMessage from 'shared-components/higlighted-message/HighlightedMessage';

import ErrorMessage from './error-message/ErrorMessage';
import FormLabel from './form-label/FormLabel';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export const DATE_PICKER_INPUT_TEST_ID = 'DATE_PICKER_INPUT_TEST_ID';

type Props = {
  control: Control;
  className?: string;
  labelClassName?: string;
  clearIcon?: React.ReactNode | null;
} & Pick<
  FormItem,
  | 'type'
  | 'label'
  | 'name'
  | 'validation'
  | 'min'
  | 'max'
  | 'disabled'
  | 'datepickerClassName'
  | 'calendarClassName'
  | 'required'
  | 'isAbsoluteError'
  | 'highlighted'
  | 'highlightedDescription'
  | 'highlightedColor'
> &
  Pick<DatePickerProps, 'onCalendarOpen' | 'onCalendarClose'>;

// Some CSS overrides are located in index.css
// If you need to modify position of calendar, you can do it through calendarClassName prop
// Example see: constants/due-diligence.ts search for: 'name: BusinessDetailsFieldNames.DATE_OF_INCORPORATION,'
// Regarding toDate and why we need it here see: https://github.com/wojtekmaj/react-date-picker/issues/263
const DatePickerInput: FC<Props> = ({
  type,
  className,
  label,
  labelClassName,
  control,
  name,
  validation,
  clearIcon = null,
  max,
  min,
  disabled,
  datepickerClassName,
  calendarClassName,
  required,
  isAbsoluteError,
  onCalendarOpen,
  onCalendarClose,
  highlighted,
  highlightedDescription,
  highlightedColor = FromItemHigligtedColor.Blue,
}) => {
  const onChangeInput = (formOnChange: (...event: any[]) => void) => (value: any) => {
    // We need this format because of legacy handling onChange value after default <input/> tag
    const formattedValue =
      type === FieldTypes.MONTH
        ? format(new Date(value), YEAR_MONTH_FORMAT_WITH_DASH)
        : format(new Date(value), YEAR_DAY_MONTH_FORMAT_WITH_DASH);

    formOnChange(formattedValue);
  };

  return (
    <div className={twMerge('mb-6 w-full relative', className)}>
      <>
        {typeof label === 'string' ? (
          <FormLabel className={labelClassName}>
            {label}
            {required && '*'}
          </FormLabel>
        ) : (
          label
        )}

        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field: { ref, value, onChange, ...field }, fieldState: { error } }) => (
            <>
              <DatePicker
                maxDate={max as Date}
                minDate={min as Date}
                maxDetail={type === FieldTypes.MONTH ? DATEPICKER_MAX_DETAIL.YEAR : DATEPICKER_MAX_DETAIL.MONTH}
                clearIcon={clearIcon}
                className={cn('date-picker-input w-full', datepickerClassName, {
                  'date-picker-input-error': error,
                  [`date-picker-input-highlighted-${highlightedColor}`]: !error && highlighted,
                })}
                format={type === FieldTypes.MONTH ? MONTH_FORMAT_WITH_SLASHES : DAY_FORMAT_WITH_SLASHES}
                calendarClassName={calendarClassName}
                onChange={onChangeInput(onChange)}
                disabled={disabled}
                value={value && isValid(new Date(value)) ? toDate(value) : null}
                onCalendarOpen={onCalendarOpen}
                onCalendarClose={onCalendarClose}
                data-testid={DATE_PICKER_INPUT_TEST_ID}
                {...field}
              />

              <ErrorMessage error={error?.message} isAbsoluteError={isAbsoluteError} />
              {!error && highlightedDescription && (
                <HighlightedMessage className='input-error-message' color={highlightedColor}>
                  {highlightedDescription}
                </HighlightedMessage>
              )}
            </>
          )}
        />
      </>
    </div>
  );
};

export default DatePickerInput;
