import { isAfter, parse } from 'date-fns';
import { CSSObjectWithLabel } from 'react-select';

import {
  DueDiligenceDeclineFieldNames,
  DueDiligenceGenerateInviteLinkFieldNames,
  FormItem,
  Option,
  SignificantControlPersonAddressInput,
  TableColumns,
  TruncatedUser,
} from 'interfaces';
import { Checkbox, DatePickerInput, Input } from 'shared-components';
import NumberInput from 'shared-components/NumberInput';
import SelectFormInput from 'shared-components/SelectFormInput';
import TextArea from 'shared-components/text-area/TextArea';

import PhoneField from '../shared-components/PhoneField';
import { getTitleWithReplacedYourWord } from '../utils';
import { DAY_FORMAT_WITH_DASH } from './dateFormats';
import { EMAIL_PATTERN, REQUIRED_MESSAGE, WEBSITE_PATTERN } from './global';
import { ColoredLabelVariants, FieldTypes } from './shared';

export const DUE_DILIGENCE_ACTIVITY_MODAL_TITLE = 'Are you still here?';

export const DUE_DILIGENCE_CHECK_LOCK_MESSAGE = 'Due Diligence is locked because another user is editing it';

export const INCORRECT_DATE_MESSAGE = 'Choose a date within 3 years';

export const DATE_IN_PAST_ERROR_MESSAGE = 'Choose a date in past';

export const DUE_DILIGENCE_PAGE_TITLE = 'Due Diligence';

export const DUE_DILIGENCE_SEND_REQUEST_MODAL_TITLE = 'Request Information from Director/Founder';

export const DUE_DILIGENCE_FOUNDER_IS_NOT_INVITED = "You're not invited to edit this Due Diligence";

export const DUE_DILIGENCE_ADMIN_SUBMIT_TEXT = 'Accept Due Diligence';

export const DUE_DILIGENCE_FOUNDER_SUBMIT_TEXT = 'Submit Due Diligence';

export const DUE_DILIGENCE_ADMIN_DECLINE_TEXT = 'Decline Due Diligence';

export const DUE_DILIGENCE_FOUNDER_DECLINE_TEXT = 'Decline';

export const DUE_DILIGENCE_INVITATION_PROPOSAL_MODAL_DESCRIPTION =
  'Would you like to invite Founder/Directors of Company to submit Due Diligence?';

export enum SendRequestFieldNames {
  FOUNDERS = 'founders',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export enum DUE_DILIGENCE_CHECK_LOCK_STATUSES {
  PENDING = 'pending',
  LOADED = 'loaded',
  IDLE = 'idle',
}

export const CORPORATE_DOCUMENTS_AND_FINANCIALS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'document', title: 'Document' },
  { id: 'date', title: 'Date' },
  { id: 'documentAttached', title: 'Document Attached', className: 'max-w-[160px]', width: 'w-[160px]' },
];

export enum BusinessDetailsFieldNames {
  BUSINESS_NAME = 'businessName',
  BUSINESS_SECTOR = 'businessSector',
  REGISTERED_NUMBER = 'registeredNumber',
  DATE_OF_INCORPORATION = 'dateOfIncorporation',
  REGISTERED_OFFICE = 'registeredOffice',
  COMPANY_APPROVAL_REQUIRED_FOR_SECONDARY_SALE = 'approvalForSecondarySale',
  PHONE = 'phone',
  WEBSITE = 'website',
  IS_SCPS_SAME_AS_IN_SCC = 'isSCPsSameAsInSCC',
}

export enum SignificationControlPersonFieldNames {
  FULL_NAME = 'fullName',
  DOB = 'dateOfBirth',
  NOMINEE = 'nominee',
  RESIDENTIAL_ADDRESS = 'address',
  FROM = 'from',
  TO = 'to',
  TILL_NOW = 'tillNow',
  CHECKED = 'checked',
}

export enum FundraisingDetailsFieldNames {
  IS_CURRENTLY_FUNDRAISING = 'isCurrentlyFundraising',
  OTHER_DETAILS = 'otherDetails',
  TARGET_AMOUNT_FOR_RAISE = 'targetAmountForRaise',
  TOTAL_AMOUNT_SO_FAR_RECEIVED = 'totalAmountSoFarReceived',
}

export enum CompanyRunwayFieldNames {
  LAST_YEAR_REVENUE = 'lastYearRevenue',
  CURRENT_YEAR_REVENUE = 'currentYearRevenue',
  NEXT_YEAR_REVENUE = 'nextYearRevenue',
  OTHER_DETAILS = 'otherDetails',
  CASH_RUNWAY = 'cashRunway',
}

export enum ManagementOwnershipFieldNames {
  FOUNDERS_OWNERSHIP_PERCENTAGE = 'foundersOwnershipPercentage',
}

export enum PreferenceRightsFieldNames {
  DEBT_PREFERENCE_RIGHTS = 'debtPreferenceRights',
}

export enum DUE_DILIGENCE_DOCUMENTS_TYPES {
  MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION = 'memorandum_and_articles_of_association',
  LATEST_DECK_BUSINESS_PLAN_FORECAST = 'latest_investor_deck_pres',
  LATEST_FORECAST = 'latest_forecast',
  FULL_YEAR_ACCOUNTS = 'full_year_accounts',
  SHAREHOLDER_AGREEMENT = 'shareholder_agreement',
  FULLY_DILUTED_CAP_TABLE = 'fully_diluted_cap_table',
  ID_PROOF_OF_ADDRESS = 'id_proof_of_address',
  LEGAL_MATTERS = 'legal_matters',
  CONFIRMATION_STATEMENT = 'confirmation_statement',
  OTHER = 'other',
}

export enum DueDiligenceSections {
  BUSINESS_DETAILS = 'businessDetails',
  SIGNIFICANT_CONTROL_PERSONS = 'significantControlPersons',
  BENEFICIAL_OWNERS = 'beneficialOwners',
  DOCUMENTS = 'documentTypes',
  LEGAL_MATTERS = 'legalMatters',
  FUNDRAISING_DETAILS = 'fundraisingDetails',
  DEBT_PREFERENCE_RIGHTS = 'debtPreferenceRights',
  MONTHS_CASH_RUNWAY_BLOCK = 'noOfMonthsCashRunway',
  MANAGEMENT_OWNERSHIP = 'foundersOwnershipPercentage',
}

export const DUE_DILIGENCE_DOCUMENTS_TYPES_KEYS = [
  DUE_DILIGENCE_DOCUMENTS_TYPES.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
  DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_DECK_BUSINESS_PLAN_FORECAST,
  DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_FORECAST,
  DUE_DILIGENCE_DOCUMENTS_TYPES.FULL_YEAR_ACCOUNTS,
  DUE_DILIGENCE_DOCUMENTS_TYPES.SHAREHOLDER_AGREEMENT,
  DUE_DILIGENCE_DOCUMENTS_TYPES.FULLY_DILUTED_CAP_TABLE,
  DUE_DILIGENCE_DOCUMENTS_TYPES.ID_PROOF_OF_ADDRESS,
  DUE_DILIGENCE_DOCUMENTS_TYPES.LEGAL_MATTERS,
  DUE_DILIGENCE_DOCUMENTS_TYPES.CONFIRMATION_STATEMENT,
  DUE_DILIGENCE_DOCUMENTS_TYPES.OTHER,
];

export const DUE_DILIGENCE_MAIN_DOCUMENTS_TYPES_KEYS = [
  DUE_DILIGENCE_DOCUMENTS_TYPES.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
  DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_DECK_BUSINESS_PLAN_FORECAST,
  DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_FORECAST,
  DUE_DILIGENCE_DOCUMENTS_TYPES.FULL_YEAR_ACCOUNTS,
  DUE_DILIGENCE_DOCUMENTS_TYPES.SHAREHOLDER_AGREEMENT,
  DUE_DILIGENCE_DOCUMENTS_TYPES.FULLY_DILUTED_CAP_TABLE,
  DUE_DILIGENCE_DOCUMENTS_TYPES.CONFIRMATION_STATEMENT,
  DUE_DILIGENCE_DOCUMENTS_TYPES.OTHER,
];

export const DUE_DILIGENCE_REQUIRED_CONFIRMATION_DOCUMENTS_TYPES_KEYS = [
  DUE_DILIGENCE_DOCUMENTS_TYPES.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
  DUE_DILIGENCE_DOCUMENTS_TYPES.FULL_YEAR_ACCOUNTS,
  DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_DECK_BUSINESS_PLAN_FORECAST,
];

export const DUE_DILIGENCE_DOCUMENT_TYPES_LABELS = {
  [DUE_DILIGENCE_DOCUMENTS_TYPES.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION]: 'Memorandum and Articles of Association',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_DECK_BUSINESS_PLAN_FORECAST]: 'Latest Investor Deck / Pres',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.LATEST_FORECAST]: 'Latest Forecast',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.FULL_YEAR_ACCOUNTS]: 'Full Year Accounts',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.SHAREHOLDER_AGREEMENT]: 'Shareholder Agreement',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.FULLY_DILUTED_CAP_TABLE]: 'Fully diluted Cap Table',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.ID_PROOF_OF_ADDRESS]: 'ID / Proof of Address',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.LEGAL_MATTERS]: 'Legal Matters',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.CONFIRMATION_STATEMENT]: 'Confirmation Statement',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.OTHER]: 'Others',
};

export const UPLOAD_DUE_DILIGENCE_DOCUMENTS_TYPES_LABELS = {
  ...DUE_DILIGENCE_DOCUMENT_TYPES_LABELS,
  [DUE_DILIGENCE_DOCUMENTS_TYPES.FULL_YEAR_ACCOUNTS]: 'Full Year Accounts',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.SHAREHOLDER_AGREEMENT]: 'Shareholder Agreement',
  [DUE_DILIGENCE_DOCUMENTS_TYPES.FULLY_DILUTED_CAP_TABLE]: 'Fully diluted Cap Table',
};

export enum DUE_DILIGENCE_STATUSES {
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
  FINISHED = 'finished',
  DECLINED = 'declined',
  ARCHIVED = 'archived',
}

export const DUE_DILIGENCE_COMMENT_TYPES: { [key in DueDiligenceSections]: string } = {
  [DueDiligenceSections.BUSINESS_DETAILS]: 'business_details',
  [DueDiligenceSections.SIGNIFICANT_CONTROL_PERSONS]: 'significant_control_persons',
  [DueDiligenceSections.BENEFICIAL_OWNERS]: 'beneficial_owners',
  [DueDiligenceSections.DOCUMENTS]: 'documents',
  [DueDiligenceSections.LEGAL_MATTERS]: 'legal_matters',
  [DueDiligenceSections.FUNDRAISING_DETAILS]: 'fundraising_details',
  [DueDiligenceSections.DEBT_PREFERENCE_RIGHTS]: 'debt_preference_rights',
  [DueDiligenceSections.MONTHS_CASH_RUNWAY_BLOCK]: 'no_of_months_cash_runway',
  [DueDiligenceSections.MANAGEMENT_OWNERSHIP]: 'founders_ownership_percentage',
};

export const dueDiligenceStatusesColoredLabel: {
  [key in DUE_DILIGENCE_STATUSES]: { children: string; variant: ColoredLabelVariants };
} = {
  [DUE_DILIGENCE_STATUSES.IN_PROGRESS]: { children: 'In progress', variant: ColoredLabelVariants.PROGRESS },
  [DUE_DILIGENCE_STATUSES.NOT_STARTED]: { children: 'Not started', variant: ColoredLabelVariants.PENDING },
  [DUE_DILIGENCE_STATUSES.FINISHED]: { children: 'Finished', variant: ColoredLabelVariants.SUCCESS },
  [DUE_DILIGENCE_STATUSES.DECLINED]: { children: 'Declined', variant: ColoredLabelVariants.ERROR },
  [DUE_DILIGENCE_STATUSES.ARCHIVED]: { children: 'Archived', variant: ColoredLabelVariants.DISABLED },
};

export const CORPORATE_DOCUMENTS_AND_FINANCIALS_BLOCK_TITLE = 'Corporate Documents and Financials';

export const CORPORATE_DOCUMENTS_AND_FINANCIALS_BLOCK_DESCRIPTION =
  'Please note the fields highlighted are mandatory. If no updated document is available, please upload the last one used';

export const LEGAL_MATTERS_BLOCK_TITLE = 'Legal Matters';

export const LEGAL_MATTERS_BLOCK_DESCRIPTION =
  'Is the Company or any party for which the Company has vicarious liability currently involved in or the subject of any form of legal action, dispute resolution, investigation, inquiry or enforcement proceedings?';

export const LEGAL_MATTERS_RADIO_CHOICES = [
  { name: 'yes', label: 'Yes', value: true },
  { name: 'no', label: 'No', value: false },
];

export const LEGAL_MATTERS_TEXT_AREA_MAX_LENGTH = 200;

export const BUSINESS_DETAILS_BLOCK_TITLE = 'Business Details';

export const COMPANY_APPROVAL_FIELD_OPTIONS: Option[] = [
  { label: 'Yes, disapplication of pre-emption required', value: 'Yes, disapplication of pre-emption required' },
  { label: 'Yes, board approval required', value: 'Yes, board approval required' },
  { label: 'Yes, investor majority approval required', value: 'Yes, investor majority approval required' },
  { label: 'Yes, (combination of the above)', value: 'Yes, (combination of the above)' },
  { label: 'No', value: 'No' },
  { label: 'Other', value: 'Other' },
  { label: "Don't know / not sure", value: "Don't know / not sure" },
];

export const BUSINESS_DETAILS_FORM_FIELDS: FormItem[] = [
  {
    name: BusinessDetailsFieldNames.BUSINESS_NAME,
    label: 'Business Name',
    placeholder: 'Business Name',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-full sm:col-span-1',
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: BusinessDetailsFieldNames.BUSINESS_SECTOR,
    label: 'Business Sector(Optional)',
    placeholder: 'Business Sector',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-full sm:col-span-2 [&>div>div]:h-[46px]',
    validation: {
      maxLength: { value: 255, message: 'The business sector must not be greater than 255 characters.' },
    },
  },
  {
    name: BusinessDetailsFieldNames.REGISTERED_NUMBER,
    label: 'Registered Number',
    placeholder: 'Registered Number',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-full sm:col-span-1',
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: BusinessDetailsFieldNames.DATE_OF_INCORPORATION,
    label: 'Date of incorporation',
    placeholder: 'Date of incorporation',
    type: FieldTypes.DATE,
    component: DatePickerInput,
    validation: { required: REQUIRED_MESSAGE },
    className: 'col-span-full sm:col-span-2 md:col-span-1',
    calendarClassName: 'ml-[-180px]',
  },
  {
    name: BusinessDetailsFieldNames.REGISTERED_OFFICE,
    label: 'Registered Office',
    placeholder: 'Registered Office',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-full md:col-span-1',
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: BusinessDetailsFieldNames.PHONE,
    label: 'Telephone Number',
    type: FieldTypes.PHONE,
    component: PhoneField,
    className: 'col-span-full md:col-span-1 xl:col-span-2',
  },
  {
    name: BusinessDetailsFieldNames.WEBSITE,
    label: 'Website',
    placeholder: 'https://',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      pattern: {
        message: 'Invalid website',
        value: WEBSITE_PATTERN,
      },
    },
    className: 'col-span-full md:col-span-2',
  },
  {
    name: BusinessDetailsFieldNames.COMPANY_APPROVAL_REQUIRED_FOR_SECONDARY_SALE,
    label: 'Company approval required for secondary sale?',
    placeholder: 'Select from dropdown',
    type: FieldTypes.SELECT,
    required: true,
    component: SelectFormInput,
    customCompanySelectStyles: {
      input: (style: CSSObjectWithLabel) => ({ ...style, height: '36px' }),
    },
    options: COMPANY_APPROVAL_FIELD_OPTIONS,
    validation: { required: REQUIRED_MESSAGE },
    className: 'col-span-3 md:col-span-2',
  },
];

export const BUSINESS_DETAILS_IS_APPLY_COMPANY_FIELD = (company1?: string, company2?: string): FormItem => ({
  name: BusinessDetailsFieldNames.IS_SCPS_SAME_AS_IN_SCC,
  label: `Are the Directors of ${company1} the same as ${company2}?`,
  type: FieldTypes.CHECKBOX,
  required: true,
  component: Checkbox,
  className: 'mt-4 ml-10',
  debounceTime: 1000,
});

export const DUE_DILIGENCE_BUSINESS_DETAILS_REQUIRED_FIELDS = [
  'businessName',
  'registeredNumber',
  'dateOfIncorporation',
  'registeredOffice',
  'approvalForSecondarySale',
];

export const DUE_DILIGENCE_SIGNIFICANT_CONTROL_PERSONS_REQUIRED_FIELDS = ['fullName', 'dateOfBirth'];

export const DUE_DILIGENCE_PERSON_ADDRESSES_REQUIRED_FIELDS = ['address', 'from', 'to'];

export const DUE_DILIGENCE_COMPANY_RUNWAY_REQUIRED_FIELDS = [
  CompanyRunwayFieldNames.LAST_YEAR_REVENUE,
  CompanyRunwayFieldNames.CURRENT_YEAR_REVENUE,
  CompanyRunwayFieldNames.NEXT_YEAR_REVENUE,
  CompanyRunwayFieldNames.CASH_RUNWAY,
];

export const DUE_DILIGENCE_FUNDRAISING_DETAILS_YES_REQUIRED_FIELDS = [
  FundraisingDetailsFieldNames.OTHER_DETAILS,
  FundraisingDetailsFieldNames.TARGET_AMOUNT_FOR_RAISE,
  FundraisingDetailsFieldNames.TOTAL_AMOUNT_SO_FAR_RECEIVED,
];

export const SIGNIFICANT_CONTROL_BLOCK_TITLE = 'Executive Directors and Persons with Significant Control';

export const SIGNIFICANT_CONTROL_BLOCK_DESCRIPTION =
  'Please provide residential addresses for at least 2 of the people listed below for the last 3 years';

export const BENEFICIAL_OWNERS_TABLE_COLUMNS = [
  { id: 'actions', title: '', width: 'w-[40px] min-w-[40px]' },
  { id: 'fullName', title: 'Full Name', width: 'w-2/12' },
  { id: 'dob', title: 'DOB', width: 'w-1/12' },
  { id: 'nominee', title: 'Nominee', width: 'w-3/12' },
  { id: 'residential_address', title: 'Residential Address', width: 'w-3/12' },
  { id: 'from_and_to', title: 'From and To', width: 'w-3/12' },
];

export const SIGNIFICANT_CONTROL_TABLE_COLUMNS: TableColumns[] = [
  ...BENEFICIAL_OWNERS_TABLE_COLUMNS.filter((column) => column.id !== 'from_and_to' && column.id !== 'nominee'),
  { id: 'from_and_to', title: 'From and To', width: 'w-4/12' },
  {
    id: 'sci',
    title: 'S.Cl > 25%',
    width: 'w-2/12',
    className: '[&>div]:justify-end',
    tooltipText: 'Person with significant control more than 25%',
    anchorId: 'sci-tooltip',
  },
];

export const SIGNIFICANT_CONTROL_COMPANIES_TABLE_COLUMNS = [
  { id: 'actions', title: '', width: 'w-[40px] min-w-[40px]' },
  { id: 'companyName', title: 'Company Name', width: 'w-2/12' },
  { id: 'viewCompany', title: '', width: 'w-8/12' },
  { id: 'sci', title: 'S.Cl > 25%', width: 'w-2/12', className: '[&>div]:justify-end' },
];

export const SIGNIFICANT_CONTROL_SUBMIT_MODAL_TITLE = 'Confirm Information';

export const SIGNIFICANT_CONTROL_SUBMIT_MODAL_DESCRIPTION =
  'Review and confirm that the information entered is correct';

export const PERSON_EMPTY_ADDRESS_VALUE = { address: '', from: '', to: '', isNew: true, tillNow: false };

export const ADD_SIGNIFICATION_CONTROL_FROM_AND_TO_FIELDS = (
  group: string,
  addresses: SignificantControlPersonAddressInput[],
  tillNowChecked: boolean,
  bypassValidation?: boolean,
): FormItem[] => {
  const today = new Date();
  const beginningOfTwentyCentury = new Date(1900, 0, 1);

  return [
    {
      name: `${group}${SignificationControlPersonFieldNames.FROM}`,
      pureName: SignificationControlPersonFieldNames.FROM,
      type: FieldTypes.MONTH,
      component: DatePickerInput,
      min: beginningOfTwentyCentury,
      max: today,
      validation: {
        validate: (fromValue: string) => {
          if (bypassValidation) {
            return true;
          }

          if (isAfter(new Date(fromValue), today)) {
            return DATE_IN_PAST_ERROR_MESSAGE;
          }

          if (!fromValue) {
            return REQUIRED_MESSAGE;
          }

          return true;
        },
      },
      className: 'mb-0 w-[145px] mr-3',
    },
    {
      name: `${group}${SignificationControlPersonFieldNames.TO}`,
      pureName: SignificationControlPersonFieldNames.TO,
      type: FieldTypes.MONTH,
      min: beginningOfTwentyCentury,
      max: today,
      component: DatePickerInput,
      disabled: tillNowChecked,
      validation: {
        validate: (toValue: string) => {
          if (bypassValidation) {
            return true;
          }

          if (tillNowChecked) {
            return true;
          }

          if (isAfter(new Date(toValue), today)) {
            return DATE_IN_PAST_ERROR_MESSAGE;
          }

          if (!toValue) {
            return REQUIRED_MESSAGE;
          }

          return true;
        },
      },
      className: 'mb-0 w-[145px]',
    },
  ];
};

export const ADD_SIGNIFICATION_CONTROL_PERSON_FIELDS = (bypassValidation?: boolean): FormItem[] => [
  {
    name: SignificationControlPersonFieldNames.FULL_NAME,
    type: FieldTypes.TEXT,
    component: Input,
    placeholder: 'Full Name',
    validation: {
      validate: (currentValue) => {
        if (bypassValidation) {
          return true;
        }

        if (!currentValue) {
          return REQUIRED_MESSAGE;
        }

        if (currentValue?.length > 250) {
          return 'Must not be greater than 250 characters.';
        }

        return true;
      },
    },
    className: 'mb-0',
  },
  {
    name: SignificationControlPersonFieldNames.DOB,
    type: FieldTypes.DATE,
    component: DatePickerInput,
    max: new Date(),
    className: 'mb-0 min-w-[125px]',
    validation: {
      validate: (currentValue: string) => {
        if (bypassValidation) {
          return true;
        }

        if (!currentValue) {
          return REQUIRED_MESSAGE;
        }

        const parsedDate = parse(currentValue, DAY_FORMAT_WITH_DASH, new Date());

        if (isNaN(parsedDate.getTime())) {
          return REQUIRED_MESSAGE;
        }

        return true;
      },
    },
  },
];

export const ADD_BENEFICIAL_OWNERS_PERSONS_FIELDS = (bypassValidation?: boolean): FormItem[] => [
  ...ADD_SIGNIFICATION_CONTROL_PERSON_FIELDS(bypassValidation),
  {
    name: SignificationControlPersonFieldNames.NOMINEE,
    type: FieldTypes.TEXT,
    component: Input,
    placeholder: 'Nominee',
    validation: {
      validate: (currentValue) => {
        if (bypassValidation) {
          return true;
        }

        if (currentValue?.length > 250) {
          return 'Must not be greater than 250 characters.';
        }

        return true;
      },
    },
    className: 'mb-0',
  },
];

export const MONTHS_CASH_RUNWAY_BLOCK_TITLE = 'Company Runway';

export const COMPANY_RUNWAY_FORM_FIELDS: FormItem[] = [
  {
    name: CompanyRunwayFieldNames.LAST_YEAR_REVENUE,
    label: 'Last year revenue (approx.)',
    placeholder: 'Enter value',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    className: 'col-span-1',
    symbol: '£',
    fractionLength: 50,
  },
  {
    name: CompanyRunwayFieldNames.CURRENT_YEAR_REVENUE,
    label: 'Current year revenue (approx.)',
    placeholder: 'Enter value',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    className: 'col-span-1',
    symbol: '£',
    fractionLength: 50,
  },
  {
    name: CompanyRunwayFieldNames.NEXT_YEAR_REVENUE,
    label: 'Next year revenue (forecast)',
    placeholder: 'Enter value',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    className: 'col-span-1',
    symbol: '£',
    fractionLength: 50,
  },
  {
    name: CompanyRunwayFieldNames.OTHER_DETAILS,
    label: 'Other details',
    placeholder: 'Enter message',
    type: FieldTypes.TEXT,
    component: TextArea,
    className: 'col-span-full disabled-light-grey [&_textarea]:min-h-[125px]',
  },
];

export const MONTHS_CASH_RUNWAY_FORM_FIELDS: FormItem[] = [
  {
    name: CompanyRunwayFieldNames.CASH_RUNWAY,
    placeholder: 'Enter message',
    component: TextArea,
    validation: { required: REQUIRED_MESSAGE },
    className: 'disabled-light-grey [&_textarea]:min-h-[125px]',
    labelClassName: 'text-sm pl-1 pb-5',
  },
];

export const MANAGEMENT_OWNERSHIP_FORM_FIELDS: FormItem[] = [
  {
    name: ManagementOwnershipFieldNames.FOUNDERS_OWNERSHIP_PERCENTAGE,
    label: 'Please indicate the estimated percentage of the company owned by company management / founders',
    placeholder: 'Select from dropdown',
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    options: [
      { label: '0-15%', value: '0-15%' },
      { label: '15-30%', value: '15-30%' },
      { label: '30-50%', value: '30-50%' },
      { label: 'Over 50%', value: 'Over 50%' },
      { label: 'Don’t Know / Not Sure', value: 'Don’t Know / Not Sure' },
    ],
    customCompanySelectStyles: {
      control: (style: CSSObjectWithLabel) => ({ ...style, maxWidth: '400px' }),
      menu: (style: CSSObjectWithLabel) => ({ ...style, maxWidth: '400px' }),
    },
    className: 'mt-6 mb-0',
    labelClassName: 'pb-6',
  },
];

export const MANAGEMENT_OWNERSHIP_COMPANY_OWNED_BLOCK_TITLE = 'Management Ownership';

export const POPULATING_DATA_LOADER_TEXT = 'Data populating, please wait';

export const UPLOAD_DOCUMENTS_MODAL_TITLE = 'Upload Documents';

export const UPLOAD_DOCUMENTS_HELP_TEXT = 'Accepted: PDF, DOCX, XLS';

export const UPLOAD_DOCUMENTS_MAX_FILE_SIZE = 7168000;

export const UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
];

export const UPLOAD_DOCUMENTS_DRAG_AREA_TITLE = 'Upload Documents';

export const UPLOAD_DOCUMENTS_SUBMIT_BUTTON_TEXT = 'Upload';

export enum UploadDocumentsFieldNames {
  DOCUMENT_TYPE = 'documentType',
  OFFICIAL_DATE = 'officialDate',
  DOCUMENT_NAME = 'documentName',
}

export const UPLOAD_DOCUMENTS_TYPES_OPTIONS = DUE_DILIGENCE_DOCUMENTS_TYPES_KEYS.map((key) => ({
  label: UPLOAD_DUE_DILIGENCE_DOCUMENTS_TYPES_LABELS[key],
  value: key,
}));

export const getUploadDocumentsFormDocumentTypeFields = (disabled?: boolean, documentTypesOptions?: Option[]) => [
  {
    name: UploadDocumentsFieldNames.DOCUMENT_TYPE,
    label: 'Type of Document',
    placeholder: 'Type of Document',
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    validation: { required: REQUIRED_MESSAGE },
    options: documentTypesOptions || UPLOAD_DOCUMENTS_TYPES_OPTIONS,
    className: 'col-start-1 col-span-2 mb-0',
    disabled,
  },
];

export const UPLOAD_DOCUMENTS_FORM_NAME_FIELD: FormItem = {
  name: UploadDocumentsFieldNames.DOCUMENT_NAME,
  label: 'Document Name',
  placeholder: 'Document Name',
  type: FieldTypes.TEXT,
  component: Input,
  validation: { required: REQUIRED_MESSAGE },
  className: 'col-start-1 col-span-3 row-start-2 mt-5 w-full',
};

export const DUE_DILIGENCE_SEND_REQUEST_FORM_FIELDS: FormItem[] = [
  {
    name: SendRequestFieldNames.FIRST_NAME,
    label: 'First Name',
    placeholder: 'First Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: 'Required information missing please input First name to continue' },
    className: 'mb-6 border-t-[1px] border-solid border-grey-200 pt-6',
  },
  {
    name: SendRequestFieldNames.LAST_NAME,
    label: 'Last Name',
    placeholder: 'Last Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: 'Required information missing please input Last name to continue' },
    className: ' mb-6',
  },
  {
    name: SendRequestFieldNames.EMAIL,
    label: 'Email address',
    placeholder: 'Email Address',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: 'Required information missing please input Email address to continue',
      pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
    },
    className: 'mb-6',
  },
];

export const GET_FOUNDERS_FORM_FIELDS = (users: TruncatedUser[] | null) => {
  const usersData =
    users?.map((user) => {
      return { label: user.email, value: user.email };
    }) || [];
  return [
    {
      type: FieldTypes.SELECT,
      name: SendRequestFieldNames.FOUNDERS,
      label: 'Founders',
      placeholder: 'Select Director/Founder from Registered Users',
      component: SelectFormInput,
      required: true,
      options: [...usersData, { label: 'Not in the list', value: 'notInTheList' }],
      validation: { required: 'Required information missing please select User Type to continue' },
      className: 'mb-6',
    },
  ];
};

export const DUE_DILIGENCE_DOCUMENTS_MODAL_TITLE = 'Due Diligence';

export const DUE_DILIGENCE_DOCUMENTS_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions' },
  { id: 'filename', title: 'Filename' },
  { id: 'documentType', title: 'Document Type' },
  { id: 'location', title: 'File location' },
  { id: 'source', title: 'Source' },
  { id: 'fileSize', title: 'File Size' },
  { id: 'officialDate', title: 'Official date' },
];

export const DUE_DILIGENCE_DOCUMENTS_ADDITIONAL_ADMIN_COLUMNS: TableColumns[] = [
  { id: 'edit', title: '', width: 'w-10' },
];

export const DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE =
  'Please enter residential addresses for the last 3 Years fully for';

export const DUE_DILIGENCE_DIRECTORS_MISSED_FULL_NAME_MESSAGE = 'Please enter a name for the new person';

export const DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE = 'Please enter correct dates for res. addresses for';

export const DUE_DILIGENCE_UNSAVED_DATA_WARNING_MESSAGE = 'Are you sure you want to leave this page?';

export const DUE_DILIGENCE_SIGNIFICANT_CONTROL_ADD_PERSON_TEXT =
  '+ Add executive directors or persons with significant control';

export const DUE_DILIGENCE_NOMINEE_SHAREHOLDERS_ADD_PERSON_TEXT = '+ Add nominee shareholders or/and beneficial owners';

export const DUE_DILIGENCE_NO_PERSONS_ADDED_DEFAULT_TEXT = 'No significant control persons added';

export const DUE_DILIGENCE_BENEFICIAL_OWNERS_NO_PERSONS_ADDED_DEFAULT_TEXT =
  'No nominee shareholders or beneficial owners persons added';

export const APPROVE_DUE_DILIGENCE_MODAL_TITLE = 'Are you sure you want to accept Due Diligence for';

export const APPROVE_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT = 'Accept';

export const DECLINE_DUE_DILIGENCE_MODAL_TITLE = 'Are you sure you want to continue with decline Due Diligence for';

export const DECLINE_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT = 'Decline';

export enum DECLINE_DUE_DILIGENCE_REASONS {
  COMPANY_APPROVAL_NOT_GRANTED = 'company_approval_not_granted',
  DEAL_LOST_ON_PRICING = 'deal_lost_on_pricing',
  LEGAL_RISK_ISSUES = 'legal_risk_issues',
  COMPETITOR_BID = 'competitor_bid',
  M_A_PROCESSING_COMMENCED = 'm_a_processing_commenced',
  LACK_OF_CASH_RUNWAY = 'lack_of_cash_runway',
  KYC_AML_FAILURE = 'kyc_aml_failure',
  OTHER = 'other',
}

const DeclineDueDiligenceOptions: Option[] = [
  { label: 'Company Approval Not Granted', value: DECLINE_DUE_DILIGENCE_REASONS.COMPANY_APPROVAL_NOT_GRANTED },
  { label: 'Deal Lost on Pricing', value: DECLINE_DUE_DILIGENCE_REASONS.DEAL_LOST_ON_PRICING },
  { label: 'Legal/Risk Issues', value: DECLINE_DUE_DILIGENCE_REASONS.LEGAL_RISK_ISSUES },
  { label: 'Competitor Bid', value: DECLINE_DUE_DILIGENCE_REASONS.COMPETITOR_BID },
  { label: 'M&A Processing Commenced', value: DECLINE_DUE_DILIGENCE_REASONS.M_A_PROCESSING_COMMENCED },
  { label: 'Lack of Cash Runway', value: DECLINE_DUE_DILIGENCE_REASONS.LACK_OF_CASH_RUNWAY },
  { label: 'KYC/AML Failure', value: DECLINE_DUE_DILIGENCE_REASONS.KYC_AML_FAILURE },
  { label: 'Other', value: DECLINE_DUE_DILIGENCE_REASONS.OTHER },
];

export const DECLINE_DUE_DILIGENCE_FORM_FIELDS: FormItem[] = [
  {
    name: DueDiligenceDeclineFieldNames.REASON_SELECT,
    label: 'Please enter the decline reason of due diligence at this stage',
    placeholder: 'Reason',
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    options: DeclineDueDiligenceOptions,
    className: 'mb-0',
    customCompanySelectStyles: {
      menuList: (style: CSSObjectWithLabel) => ({ ...style, maxHeight: '120px' }),
    },
    validation: {
      required: 'Required information missing please input Reason to continue',
    },
  },
];

export const DECLINE_DUE_DILIGENCE_OPTIONAL_FORM_FIELDS = (isTypeEntity: boolean, entityName: string): FormItem[] => [
  {
    name: DueDiligenceDeclineFieldNames.REASON_TEXT,
    label: getTitleWithReplacedYourWord('Please add your Reason', entityName, isTypeEntity),
    placeholder: 'Reason',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'mb-0',
    validation: {
      required: 'Required information missing please input Reason to continue',
      maxLength: { message: 'The Reason length should be less than 50 characters.', value: 50 },
    },
  },
];

export enum DueDiligencePersonsFormNamesEnum {
  BENEFICIAL_OWNERS = 'beneficialOwners',
  DIRECTORS = 'directors',
}

export enum DueDiligenceCompanyStatuses {
  ACTIVE = 'active',
  DISSOLVED = 'dissolved',
  LIQUIDATION = 'liquidation',
  RECEIVERSHIP = 'receivership',
  ADMINISTRATION = 'administration',
  OPEN = 'open',
  CLOSED = 'closed',
  REGISTERED = 'registered',
  REMOVED = 'removed',
  CONVERTED_CLOSED = 'converted-closed',
  VOLUNTARY_ARRANGEMENT = 'voluntary-arrangement',
  INSOLVENCY_PROCEEDINGS = 'insolvency-proceedings',
}

export const dueDiligenceCompanyStatusesColoredLabel: {
  [key in DueDiligenceCompanyStatuses]: { children: string; variant: ColoredLabelVariants };
} = {
  [DueDiligenceCompanyStatuses.ACTIVE]: { children: 'Active', variant: ColoredLabelVariants.SUCCESS },
  [DueDiligenceCompanyStatuses.DISSOLVED]: { children: 'Dissolved', variant: ColoredLabelVariants.ERROR },
  [DueDiligenceCompanyStatuses.LIQUIDATION]: { children: 'Liquidation', variant: ColoredLabelVariants.ERROR },
  [DueDiligenceCompanyStatuses.RECEIVERSHIP]: { children: 'Receiver Action', variant: ColoredLabelVariants.PENDING },
  [DueDiligenceCompanyStatuses.ADMINISTRATION]: {
    children: 'In Administration',
    variant: ColoredLabelVariants.PENDING,
  },
  [DueDiligenceCompanyStatuses.OPEN]: { children: 'Open', variant: ColoredLabelVariants.SUCCESS },
  [DueDiligenceCompanyStatuses.CLOSED]: { children: 'Closed', variant: ColoredLabelVariants.ERROR },
  [DueDiligenceCompanyStatuses.REGISTERED]: { children: 'Registered', variant: ColoredLabelVariants.SUCCESS },
  [DueDiligenceCompanyStatuses.REMOVED]: { children: 'Removed', variant: ColoredLabelVariants.ERROR },
  [DueDiligenceCompanyStatuses.CONVERTED_CLOSED]: {
    children: 'Converted / Closed',
    variant: ColoredLabelVariants.ERROR,
  },
  [DueDiligenceCompanyStatuses.VOLUNTARY_ARRANGEMENT]: {
    children: 'Voluntary Arrangement',
    variant: ColoredLabelVariants.PENDING,
  },
  [DueDiligenceCompanyStatuses.INSOLVENCY_PROCEEDINGS]: {
    children: 'Insolvency Proceedings',
    variant: ColoredLabelVariants.PENDING,
  },
};

export const DUE_DILIGENCE_GENERATE_INVITE_LINK_FORM_FIELDS: FormItem[] = [
  {
    name: DueDiligenceGenerateInviteLinkFieldNames.EMAIL,
    label: 'Email Address',
    placeholder: 'Email Address',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Email to continue',
      pattern: { message: 'Invalid Email Address', value: EMAIL_PATTERN },
    },
    className: 'mb-2',
  },
];

export const DUE_DILIGENCE_FUNDRAISING_OTHERS_DETAILS_FIELD: FormItem = {
  name: FundraisingDetailsFieldNames.OTHER_DETAILS,
  label:
    'Please detail terms of the raise, indicative company valuation and include a copy any relevant documents (e.g. term sheet, ASA etc) or provide any additional relevant information.',
  placeholder: 'Type answer here',
  type: FieldTypes.TEXT,
  validation: { required: REQUIRED_MESSAGE },
  component: TextArea,
  className: 'mb-0 disabled-light-grey [&_textarea]:min-h-[125px]',
};

export const DUE_DILIGENCE_FUNDRAISING_DETAILS_FIELDS: FormItem[] = [
  {
    name: FundraisingDetailsFieldNames.TARGET_AMOUNT_FOR_RAISE,
    label: 'Target Amount for raise',
    type: FieldTypes.NUMBER,
    validation: { required: REQUIRED_MESSAGE },
    component: NumberInput,
    fractionLength: 50,
  },
  {
    name: FundraisingDetailsFieldNames.TOTAL_AMOUNT_SO_FAR_RECEIVED,
    label: 'Amount of target raised already',
    type: FieldTypes.NUMBER,
    validation: { required: REQUIRED_MESSAGE },
    component: NumberInput,
    fractionLength: 50,
    className: 'mb-0',
  },
];

export const DUE_DILIGENCE_PREFERENCE_RIGHTS_FIELDS: FormItem[] = [
  {
    name: PreferenceRightsFieldNames.DEBT_PREFERENCE_RIGHTS,
    label:
      'Please outline any debt that should be taken into account and any preference rights attached to the shares that may affect valuation.',
    placeholder: 'Type answer here',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { required: REQUIRED_MESSAGE },
    className: 'mb-0 disabled-light-grey [&_textarea]:min-h-[125px]',
    labelClassName: 'text-sm pl-1 pb-6',
  },
];
