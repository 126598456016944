import React from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import 'fonts/ClearSans/clear-sans.bold.ttf';
import 'fonts/ClearSans/clear-sans.regular.ttf';
import 'react-tooltip/dist/react-tooltip.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.css';

import App from './App';

Sentry.init({
  dsn: window?._env_?.REACT_APP_SENTRY_DNS || process.env.REACT_APP_SENTRY_DNS || '',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL || ''],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
