import React, { FC, useMemo, useState, Fragment } from 'react';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

import { SHARE_EXCHANGE_OLD_STRUCTURE_PROGRESS_BAR_MESSAGE } from 'constants/share-exchange';
import { selectDealStages } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { formatStepsProgressData } from 'utils';

const STEP_TOOLTIP_CORE_ID = 'step-tooltip-core-id-';

type Props = {
  stage: string;
  dealId: string;
  stoppedDeal: boolean;
  className?: string;
  stepClassName?: string;
  onClick?: VoidFunction;
};

const StepProgressV2: FC<Props> = ({ dealId, className, stage, stoppedDeal, onClick }) => {
  const [selectedTooltipId, setSelectedTooltipId] = useState('');

  const stages = useAppSelector(selectDealStages);

  const { steps, currentStep: currentStepIndex } = useMemo(() => {
    return formatStepsProgressData(stages, stage || '');
  }, [stage, stages]);

  if (currentStepIndex === null)
    return <p className='text-grey-500 text-xl'>{SHARE_EXCHANGE_OLD_STRUCTURE_PROGRESS_BAR_MESSAGE}</p>;

  if (!steps?.length) return <div className='skeleton-loader h-2' />;

  return (
    <div className={cn(className, 'w-full flex', { 'cursor-pointer': onClick })} onClick={onClick}>
      <div className='flex w-full justify-between items-center'>
        {steps.map(({ key, isCompleted, text }, index) => {
          const anchorId = `${STEP_TOOLTIP_CORE_ID}_${key}_${dealId}`;
          const highlightStoppedStep = stoppedDeal && isCompleted && !steps[index + 1].isCompleted;
          return (
            <Fragment key={key}>
              <div style={{ width: `${100 / steps?.length}%` }} className='relative mr-2'>
                {index !== 0 && (
                  <div
                    className={cn('h-1.5 border border-grey-300 absolute w-full top-3 right-[95%]', {
                      'bg-green-600': isCompleted,
                    })}
                  />
                )}
                <div
                  onMouseEnter={() => setSelectedTooltipId(anchorId)}
                  onMouseLeave={() => setSelectedTooltipId('')}
                  id={anchorId}
                  className={cn('w-7 h-7 rounded-full flex items-center justify-center text-lg relative z-10', {
                    'bg-red-500 text-white': highlightStoppedStep,
                    'bg-green-600 text-white': isCompleted && !highlightStoppedStep,
                    'bg-white text-grey-300 border border-grey-300': !isCompleted,
                  })}
                >
                  {index + 1}
                </div>
              </div>

              <Tooltip
                anchorId={anchorId}
                isOpen={selectedTooltipId === anchorId}
                offset={5}
                noArrow
                positionStrategy='fixed'
                className='step-progress text-sm text-grey-500 z-50'
              >
                <span>{text}</span>
                {highlightStoppedStep && <span className='text-red-500 font-bold ml-0.5'>- STOPPED</span>}
              </Tooltip>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default StepProgressV2;
