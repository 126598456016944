import React, { FC, useEffect, useMemo, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { ADMIN_COMPANIES_TABLE_COLUMNS } from 'constants/companies';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getAdminCompanies } from 'modules/companies/action';
import { selectCompaniesData, selectCompanyDataIsCalled } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';
import { checkIsAdmin, getUserFiltersQuery } from 'utils';

import { AddCompanyModal } from '../index';
import AdminCompaniesTableRow from './AdminCompaniesTableRow';
import AdminDeleteCompanyModal from './AdminDeleteCompanyModal';
import CompaniesPageFilter from './CompaniesPageFilter';

const COMPANIES_PER_PAGE = 7;

type Props = {
  onClickCompanyIac: (companyId: number, companyName: string) => void;
};

const CompaniesTable: FC<Props> = ({ onClickCompanyIac }) => {
  const [selectedCompany, setSelectedCompany] = useState<{
    companyId: number | null;
    hasLiveDealParameter: boolean;
  }>({
    companyId: null,
    hasLiveDealParameter: false,
  });

  const { isOpen, onClose, onOpen } = useModal();

  const dispatch = useAppDispatch();

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { search } = useLocation();
  const { ddStatus, iacStatus } = qs.parse(search);

  const { list: companies, isLoading, ...companiesPaginationData } = useAppSelector(selectCompaniesData);
  const isCompanyDeleted = useAppSelector(selectCompanyDataIsCalled);

  const isAdmin = useMemo(() => {
    return checkIsAdmin();
  }, []);

  const handleDeleteAction = (companyId: number, hasLiveDealParameter: boolean) => {
    setSelectedCompany({ companyId, hasLiveDealParameter });
  };

  useEffect(() => {
    dispatch(
      getAdminCompanies({
        page,
        per_page: COMPANIES_PER_PAGE,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter: `${filter || ''}&${getUserFiltersQuery({
          ddStatus,
          iacStatus,
        })}`.replace(/&$/, ''),
        isAdmin,
      }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column, filter, isAdmin, isCompanyDeleted, ddStatus, iacStatus]);

  return (
    <div className='flex flex-col'>
      <div>
        <div className='flex justify-between flex-col sm:flex-row gap-x-2'>
          <SearchForm
            className='mb-4 xs:mb-12 items-center flex-col xs:flex-row gap-4 xs:gap-0'
            handleFilter={handleFilter}
            placeholder='Search by Company Name or Company Number'
          />
          <AddCompanyModal page={page} perPage={COMPANIES_PER_PAGE} setPage={setPage} />
        </div>
        <CompaniesPageFilter />
      </div>
      <Table onUpdateSorting={updateSorting} columns={ADMIN_COMPANIES_TABLE_COLUMNS}>
        {isLoading ? (
          <TableSkeleton rowsNumber={COMPANIES_PER_PAGE} columnsNumber={5} />
        ) : (
          companies &&
          companies.map((company) => (
            <AdminCompaniesTableRow
              key={company.id}
              onClickCompanyIac={onClickCompanyIac}
              onDeleteCompanyClick={handleDeleteAction}
              company={company}
              onOpen={onOpen}
            />
          ))
        )}
      </Table>

      <Pagination currentPage={page} updatePage={setPage} {...companiesPaginationData} />
      <AdminDeleteCompanyModal isOpen={isOpen} onClose={onClose} {...selectedCompany} />
    </div>
  );
};

export default CompaniesTable;
