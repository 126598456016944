import { TabItem } from 'interfaces';
import UserPermissionsContainer from 'page-components/users/user-permissions/UserPermissionsContainer';
import UserDeals from 'page-components/users/UserDeals';

export enum InvitedUserStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export enum UserTypes {
  ADMIN = 'admin',
  INVESTOR = 'investor',
  FOUNDER = 'founder',
  FM_USER = 'fmuser',
}

export enum InvestorSubTypes {
  INDIVIDUAL = 'individual',
  ENTITY = 'entity',
}

export const UserTypesLabels = {
  [UserTypes.ADMIN]: 'Admin',
  [UserTypes.INVESTOR]: 'Investor',
  [UserTypes.FOUNDER]: 'Founder',
  [UserTypes.FM_USER]: 'FM User',
};

export enum InviteUserType {
  DUE_DILIGENCE = 'due_diligence',
  INVESTOR_TO_COMPANY = 'investor_to_company',
  INVESTOR_TO_ENTITY_COMPANY = 'investor_to_entity_company',
}

export enum UserProfileInfo {
  EMAIL_ADDRESS = 'Email Address',
  ORGANIZATION = 'Organization',
  LAST_ONLINE = 'Last Online',
  PHONE_NUMBER = 'Phone No',
}

export enum UserIntegrationType {
  PERSONAL = 'personal',
  COMPANIES = 'companies',
}

export const USERS_PERMISSIONS_ENABLED_ERROR = 'Action denied. At least one permission should be enabled';

export const USER_PAGE_TABS: TabItem[] = [
  { id: 'deals', name: 'Deals', component: UserDeals },
  { id: 'activity', name: 'Activity Log' },
  { id: 'permissions', name: 'Permissions', component: UserPermissionsContainer },
];

export const USER_TYPES_OPTIONS = Object.values(UserTypes).map((userType) => ({
  label: UserTypesLabels[userType],
  value: userType,
}));
