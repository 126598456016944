import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FINISH_REGISTRATION_PAGE_TITLE } from 'constants/sign-up';
import { SetPasswordInput } from 'interfaces';
import { asyncFinishRegistration } from 'modules/auth/action';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo, NeedHelpLink, SetPasswordForm } from 'page-components';
import { getTitleWithReplacedYourWord } from 'utils';

import {
  RESET_PASSWORD_PAGE_ADDITIONAL_TEXT,
  SET_PASSWORD_AUTH_LAYOUT_CHILDREN_CLASSNAMES,
} from '../constants/reset-password';

const FinishRegistration = () => {
  const dispatch = useAppDispatch();
  const { hash } = useParams();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const onSubmit: SubmitHandler<SetPasswordInput> = (data) => {
    if (!hash) {
      return;
    }
    dispatch(asyncFinishRegistration({ ...data, hash }));
  };

  return (
    <AuthLayout childrenClassName={SET_PASSWORD_AUTH_LAYOUT_CHILDREN_CLASSNAMES}>
      <HeaderInfo title={FINISH_REGISTRATION_PAGE_TITLE}>
        {getTitleWithReplacedYourWord(RESET_PASSWORD_PAGE_ADDITIONAL_TEXT, entityName, isTypeEntity)}
      </HeaderInfo>

      <SetPasswordForm onSubmit={onSubmit} />

      <NeedHelpLink />
    </AuthLayout>
  );
};

export default FinishRegistration;
