import React, { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { USERS_TABLE_COLUMNS } from 'constants/users';
import useModal from 'hooks/use-modal/useModal';
import { UserData, SortingOrder, ButtonDropdownActions, UserActionsParamsType } from 'interfaces';
import { useAppDispatch } from 'modules/store';
import { sendFinishRegistrationInvite } from 'modules/users/action';
import Table, { TableSkeleton } from 'shared-components/table';

import DeleteUserModal from '../user-delete/DeleteUserModal';
import UsersTableItem from './UsersTableItem';

type Props = {
  users: UserData[] | null;
  updateSorting: (column: string, order: SortingOrder) => void;
  loading: boolean;
  rowsNumber: number;
  callback?: VoidFunction;
};

type DeleteUserData = {
  fullName: string | undefined;
  id: string | undefined;
};

const UsersTable: FC<Props> = ({ rowsNumber, updateSorting, users, loading, callback }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { onOpen, ...modalState } = useModal();

  const [invitationList, setInvitationList] = useState<number[]>([]);

  const [deleteUserData, setDeleteUserData] = useState<DeleteUserData>({ fullName: '', id: '' });

  const sendFinishInvitation = (id: number) => {
    dispatch(sendFinishRegistrationInvite(id));
    setInvitationList((list) => [...list, id]);
  };

  const getUserActions = ({ id, firstName, lastName, isRegistrationFinished }: UserActionsParamsType) => {
    return [
      {
        title: 'Edit user',
        id: 'edit',
        action: () => {
          navigate(`${ROUTES.admin}${ROUTES.user}`.replace(':id', id.toString()));
        },
      },
      {
        title: 'Delete user',
        id: 'delete',
        action: () => {
          onOpen();
          setDeleteUserData({ fullName: `${firstName} ${lastName}`, id: id.toString() });
        },
      },
      ...(!isRegistrationFinished
        ? [
            {
              title: 'Send Invite',
              id: 'invite',
              action: () => sendFinishInvitation(id),
              disabled: invitationList.includes(id),
            },
          ]
        : []),
    ] as ButtonDropdownActions[];
  };

  if (!users && !loading) {
    return <div>No users found</div>;
  }

  return (
    <>
      <Table onUpdateSorting={updateSorting} columns={USERS_TABLE_COLUMNS}>
        {loading ? (
          <TableSkeleton rowsNumber={rowsNumber} columnsNumber={USERS_TABLE_COLUMNS.length} />
        ) : (
          users &&
          users.map((user) => (
            <UsersTableItem
              {...user}
              key={user.id}
              getActions={getUserActions}
              sendInvitation={sendFinishInvitation}
              disabledInvitation={invitationList.includes(user.id)}
            />
          ))
        )}
      </Table>
      <DeleteUserModal callback={callback} {...modalState} {...deleteUserData} />
    </>
  );
};

export default UsersTable;
