import { ChangeEventHandler, FC, FocusEventHandler, FunctionComponent, ReactNode, SVGProps } from 'react';

import { RegisterOptions } from 'react-hook-form';
import { StylesConfig } from 'react-select';

import { SHARE_EXCHANGE_DOCUMENT_KEYS } from 'constants/deals';
import { FieldTypes } from 'constants/shared';

import { FieldTooltipVariants } from '../constants/shared/input';

export interface Option {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export interface RadioOption {
  id: string;
  label: string;
  value: string;
}

export enum FromItemHigligtedColor {
  Blue = 'blue',
  Red = 'red',
}

export interface FormItem {
  name: string;
  pureName?: string;
  type?: FieldTypes;
  label?: FunctionComponent<any> | ReactNode | string;
  required?: boolean;
  placeholder?: string;
  options?: Option[];
  component: FunctionComponent<any>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onCreateOption?: (inputValue: string) => boolean;
  validation?: RegisterOptions;
  icon?: FC<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  isAbsoluteError?: boolean;
  className?: string;
  datepickerClassName?: string;
  calendarClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  max?: string | Date;
  min?: string | Date;
  tooltipVariant?: FieldTooltipVariants;
  tooltipContent?: string | FunctionComponent<any>;
  radioOptions?: RadioOption[];
  radioLegend?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  customCompanySelectStyles?: StylesConfig;
  symbol?: string;
  description?: string;
  tabIndex?: number;
  isSearchable?: boolean;
  colSpan?: number;
  fractionLength?: number;
  highlighted?: boolean;
  highlightedDescription?: string;
  highlightedColor?: FromItemHigligtedColor;
  debounceTime?: number;
  dataTestId?: string;
}

export interface SharedHighlighted {
  highlighted?: boolean;
  highlightedDescription?: string;
  highlightedColor?: FromItemHigligtedColor;
}

export interface TabItem {
  id: string;
  name: string;
  component?: FunctionComponent<any>;
}

export interface ButtonDropdownActions {
  title: string;
  id: string;
  action: VoidFunction;
  isOpenOnAction?: boolean;
  disabled?: boolean;
  icon?: FC<SVGProps<SVGSVGElement>>;
}

export interface ButtonAction {
  id: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  action: VoidFunction;
  tooltipText?: string;
  className?: string;
  disabled?: boolean;
}

export interface GraphItem {
  name: string;
  uv?: number;
  secondChart?: number;
}

export interface MultiGraphLegend {
  value: string;
  className?: string;
}

export interface ChartItem {
  name: string;
  value: string | number | undefined;
}

export interface DropdownSelectItem {
  id: string;
  title: string;
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface StepProgressItem {
  key: string;
  className?: string;
  filled: string;
  isCompleted: boolean;
  text: string;
  documentKey: SHARE_EXCHANGE_DOCUMENT_KEYS | null;
  hidden?: boolean;
}

export interface StepItem {
  id: string;
  title?: string;
  tooltip?: string;
}

export interface FileData {
  id: number;
  name: string;
  size: string;
}

export interface Comment<T> {
  id: number;
  text: string;
  author: string;
  createdAt: string;
  type: T;
}

export interface SliderItem {
  id: string;
  title?: string;
  description?: string;
  imageSrc?: string;
}

export interface StepperItem {
  step: number;
  title?: string;
  description?: string;
  onClick?: VoidFunction;
}

export interface VerticalStepperItem {
  step: number;
  content: string;
}

export interface FormFieldBasicParams {
  className?: string;
  tabIndex?: number;
}

export type MissedTooltipPlaces =
  | 'bottom-start'
  | 'bottom-end'
  | 'top-start'
  | 'top-end'
  | 'right-end'
  | 'right-start'
  | 'left-end';

export type FormFieldPossibleValue = string | number | Option | null | undefined | boolean;
