import React, { FC, useMemo, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as CheckMarkIcon } from 'assets/svg/check-mark.svg';
import {
  DUE_DILIGENCE_ADMIN_DECLINE_TEXT,
  DUE_DILIGENCE_ADMIN_SUBMIT_TEXT,
  DUE_DILIGENCE_FOUNDER_DECLINE_TEXT,
  DUE_DILIGENCE_FOUNDER_SUBMIT_TEXT,
} from 'constants/due-diligence';
import { PLAND_SUPPORT_EMAIL } from 'constants/global';
import { ButtonVariants } from 'constants/shared/button';
import { selectIsDueDiligenceEditable, selectIsDueDiligenceFinishedOrDeclined } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { Button, ButtonWithTooltip, Checkbox } from 'shared-components';

type Props = {
  className?: string;
  onSubmit: (isDeclining?: boolean) => void;
  isAdmin?: boolean;
  isDisabled?: boolean;
};

const TOOLTIP_BUTTON_ID = 'submit-button-tooltip';

const CONFIRMATION_TEXT =
  'To my knowledge, the answers and supporting documents provided by the potential investee company are complete, true, fair and not misleading.';

const SubmitDueDiligenceBlock: FC<Props> = ({ className, onSubmit, isAdmin, isDisabled }) => {
  const isDueDiligenceFinishedOrDeclined = useAppSelector(selectIsDueDiligenceFinishedOrDeclined);
  const isDueDiligenceEditable = useAppSelector(selectIsDueDiligenceEditable);

  const [confirmed, setConfirmed] = useState(false);

  const submitTextByRole = useMemo(
    () => (isAdmin ? DUE_DILIGENCE_ADMIN_SUBMIT_TEXT : DUE_DILIGENCE_FOUNDER_SUBMIT_TEXT),
    [isAdmin],
  );

  const declineTextByRole = useMemo(
    () => (isAdmin ? DUE_DILIGENCE_ADMIN_DECLINE_TEXT : DUE_DILIGENCE_FOUNDER_DECLINE_TEXT),
    [isAdmin],
  );

  const isSubmitButtonDisabled =
    (!isAdmin && isDisabled) || (!isAdmin && !confirmed) || isDueDiligenceFinishedOrDeclined || !isDueDiligenceEditable;

  const submitButtonTooltipClassName = useMemo(() => {
    if (!isAdmin && isDisabled) {
      return 'error-tooltip-message';
    }

    return '';
  }, [isAdmin, isDisabled]);

  const submitButtonTooltipMessage = useMemo(() => {
    if (!isSubmitButtonDisabled) {
      return null;
    }

    if (!isAdmin && isDisabled) {
      return 'Please complete the missing details highlighted in red before submitting Due Diligence';
    }

    if (!isAdmin && !confirmed && isDueDiligenceEditable) {
      return 'Please confirm submitting Due Diligence';
    }

    if (!isAdmin && !isDueDiligenceEditable) {
      return `The Due Diligence form has already been submitted for review, for any amendments please contact planD at ${PLAND_SUPPORT_EMAIL}`;
    }

    if (isDueDiligenceFinishedOrDeclined) {
      return 'Due Diligence is already Finished/Declined';
    }

    return null;
  }, [
    isSubmitButtonDisabled,
    isDueDiligenceEditable,
    isDueDiligenceFinishedOrDeclined,
    isAdmin,
    isDisabled,
    confirmed,
  ]);

  const handleChangeConfirmed = () => {
    setConfirmed((prevConfirmed) => !prevConfirmed);
  };

  return (
    <div className={cn('w-full border border-grey-300 rounded-10 py-6 px-12', className)}>
      {!isAdmin && (
        <>
          <div className='flex items-center'>
            <CheckMarkIcon className='fill-green-600 w-11 h-11 mb-1 mr-6' />

            <div className='text-3xl font-bold'>{submitTextByRole}</div>
          </div>

          <div className='mt-6'>{CONFIRMATION_TEXT}</div>

          {!isDueDiligenceFinishedOrDeclined && isDueDiligenceEditable && (
            <div className='flex mt-4'>
              <div className='mr-5'>I confirm</div>
              <Checkbox onChange={handleChangeConfirmed} checked={confirmed} />
            </div>
          )}
        </>
      )}

      <div
        className={cn('flex items-center flex-col md:flex-row gap-4 md:gap-0 relative', {
          'mt-5': !isAdmin,
          'h-full justify-center': isAdmin,
        })}
      >
        <ButtonWithTooltip
          variant={ButtonVariants.PRIMARY}
          wrapperClassName='w-full md:w-auto md:basis-7/12'
          tooltipClassName={submitButtonTooltipClassName}
          tooltipContent={submitButtonTooltipMessage}
          anchorId={TOOLTIP_BUTTON_ID}
          disabled={isSubmitButtonDisabled}
          onClick={() => onSubmit()}
        >
          {submitTextByRole}
        </ButtonWithTooltip>

        {isAdmin && !isDueDiligenceFinishedOrDeclined && (
          <>
            <div className='basis-1/12 text-center'>or</div>
            <Button variant={ButtonVariants.SECONDARY} onClick={() => onSubmit(true)} className='basis-4/12'>
              {declineTextByRole}
            </Button>
          </>
        )}

        {!isAdmin && <div className='basis-1/12' />}
      </div>
    </div>
  );
};

export default SubmitDueDiligenceBlock;
