import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { DEAL_STATUSES, SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { LocalStorageKeys } from 'constants/global';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { Deal } from 'interfaces';
import { Button } from 'shared-components';
import { StepProgressV2 } from 'shared-components/step-progress';
import Typography from 'shared-components/Typography';
import { getDealDetailsLink } from 'utils';
import { checkIsCurrentDocumentCorrect, getStorageDealsStagesLabels } from 'utils/deals';

type Props = {
  deal: Deal;
};

const ShareExchangeInvestorSnippetItem = ({ deal }: Props) => {
  const navigate = useNavigate();

  const [storageDealsStagesLabels, { nextStage }] = getStorageDealsStagesLabels(deal?.id);

  const itemTitle = 'Company Name';
  const itemName = deal.companyName;

  const handleClickStepProgress = () => {
    navigate(getDealDetailsLink(deal?.id));
  };

  const isPendingApproval = !!(
    deal.isAppropriatenessQuestionnaireSigned && deal.stage === SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC
  );

  const buttonText = useMemo(() => {
    if (isPendingApproval) return 'Pending Approval';

    if (deal.status === DEAL_STATUSES.DONE || deal.status === DEAL_STATUSES.STOPPED) {
      return 'View';
    }

    if (deal.stage === SHARE_EXCHANGE_STAGES.APPROVAL || deal.stage === SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE) {
      return 'Pending';
    }

    if (deal.stage === SHARE_EXCHANGE_STAGES.INVOICE) {
      return 'Pay Now';
    }

    return 'Continue';
  }, [deal.stage, deal.status, isPendingApproval]);

  const viewDealClick = () => {
    const isDocumentUrlCorrect = checkIsCurrentDocumentCorrect(deal.documentsUrls.currentDoc);

    if (deal.stageLabel === nextStage) {
      localStorage.setItem(
        LocalStorageKeys.DEALS_STAGES_LABELS,
        JSON.stringify({ ...storageDealsStagesLabels, [deal.id]: '' }),
      );
    }

    if (
      (nextStage && nextStage !== deal.stageLabel) ||
      deal.status === DEAL_STATUSES.DONE ||
      deal.status === DEAL_STATUSES.STOPPED ||
      isPendingApproval ||
      deal.stage === SHARE_EXCHANGE_STAGES.APPROVAL ||
      deal.stage === SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE ||
      !isDocumentUrlCorrect
    ) {
      navigate(getDealDetailsLink(deal.id));
      return;
    }
    window.open(deal.documentsUrls.currentDoc, '_self');
  };

  return (
    <div className='w-full my-2 border border-grey-300 px-8 py-5 flex flex-col md:flex-row justify-between space-y-6 md:space-y-0'>
      <div className='flex flex-col md:flex-row w-full overflow-x-auto space-y-6 md:space-y-0'>
        <div className='w-full md:w-[150px] md:min-w-[150px] mr-6'>
          <Typography className='mb-2' variant={TypographyVariants.BODY_SMALL}>
            {itemTitle}
          </Typography>
          <div className='text-lg font-bold'>{itemName}</div>
        </div>
        <StepProgressV2
          stoppedDeal={deal.status === DEAL_STATUSES.STOPPED}
          dealId={deal.dealId}
          stage={deal.stage}
          onClick={handleClickStepProgress}
          className='md:mr-6 mt-2.5 w-full overflow-x-auto'
        />
      </div>

      <div className='flex items-center justify-end'>
        <Button variant={ButtonVariants.SECONDARY} onClick={viewDealClick} className='md:ml-2 min-w-150'>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default ShareExchangeInvestorSnippetItem;
