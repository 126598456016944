import React, { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  companyName: string;
}

const ShareExchangeCompanyGroup: FC<Props> = ({ companyName, children }) => {
  if (!children) return null;

  return (
    <div className='mb-6'>
      <h4 className='font-medium text-lg'>{companyName}</h4>
      <div>{children}</div>
    </div>
  );
};

export default ShareExchangeCompanyGroup;
